import React, { useEffect, useState } from 'react';
import useEditingBlockStore from '../../stores/editing-block-store';
import { Frame, frameSchema } from '../../types/renderables';
import { parseFrame } from '../../utils/figma-communication';
import Select from 'react-select';
import { log } from 'console';

const SandboxForm: React.FC = () => {
    const { setFramesAndImages } = useEditingBlockStore();

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>(null);
    const [templateNames, setTemplateNames] = useState<string[]>([]);
    const [templateName, setTemplateName] = useState<string>('');

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const res = await fetch('/api/get-available-template-names');
                const data = await res.json();
                setTemplateNames(data.templates);
                setTemplateName(data.templates[0]);
            } catch (error) {
                console.error('Error fetching templates', error);
            }
        };

        fetchTemplates();
    }, []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        try {
            const res = await fetch(`/api/get-frames/${templateName}`, {
                method: 'GET',
            });

            const data = await res.json();
            if (data.result === "error") {
                setResponse(data);
            } else {
                log('herer', data['frames']);
                const frames: Frame[] = [];
                data['frames'].forEach(frame => {
                    frames.push(parseFrame(frame));
                });
                setFramesAndImages({ frames: frames, images: data.images });
                setResponse(data);
            }
        } catch (error) {
            console.error('Error submitting form', error);
            setResponse({ result: "error", message: "Error submitting form" });
        } finally {
            setLoading(false);
        }
    };

    const options = templateNames.map(template => ({ value: template, label: template }));

    return (
        <div className="container mt-5">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="templateName" className="form-label">Выбрать шаблон</label>
                    <Select
                        id="templateName"
                        options={options}
                        value={options.find(option => option.value === templateName)}
                        onChange={(selectedOption) => setTemplateName(selectedOption?.value || '')}
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Загрузка...' : 'Отправить'}
                </button>
            </form>
            {(response && response.result === "error") && (
                <div className="mt-5 text-danger" style={{ textAlign: 'left' }}>
                    Message: <span dangerouslySetInnerHTML={{ __html: (response.message || '').replace(/\n/g, '<br/>') }} /> <br />
                    <span dangerouslySetInnerHTML={{ __html: (('Error: ' + response.traceback) || '').replace(/\n/g, '<br/>') }} />
                </div>
            )}
        </div>
    );
};

export default SandboxForm;
