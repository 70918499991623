import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { fabric } from 'fabric';

interface ClipboardState {
    clipboard: any[] | null;  // Use any[] to hold serialized objects
    copyToClipboard: (objects: fabric.Object[]) => void;
    pasteFromClipboard: () => Promise<fabric.Object[] | null>;
}

export const useClipboardStore = create<ClipboardState>()(
    devtools((set, get) => ({
        clipboard: null,

        copyToClipboard: (objects) => {
            const serializedObjects = objects.map(obj => obj.toObject(['id', 'class']));  // Serialize the objects
            set({ clipboard: serializedObjects });
        },

        pasteFromClipboard: async () => {
            const clipboard = get().clipboard;
            if (!clipboard) return null;

            return new Promise<fabric.Object[]>((resolve) => {
                fabric.util.enlivenObjects(clipboard, (enlivenedObjects) => {
                    resolve(enlivenedObjects);
                });
            });
        },
    }), { name: "ClipboardStore" })
);
