import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useProductListStore } from '../../stores/product-list-store';
import { Button, Form, Spinner } from 'react-bootstrap';

interface TemplateOption {
    value: string;
    label: string;
}

const InfographicsCreator: React.FC = () => {
    const { selectedProducts } = useProductListStore();
    const [templates, setTemplates] = useState<TemplateOption[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateOption | null>(null);
    const [additionalPrompt, setAdditionalPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch('/api/get-available-template-names');
                const data = await response.json();
                const templateOptions = data.templates.map((template: string) => ({
                    value: template,
                    label: template
                }));
                setTemplates(templateOptions);
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        fetchTemplates();
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedTemplate) {
            alert('Please select a template.');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch('/api/create-infographics', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    products: selectedProducts,
                    template: selectedTemplate.value,
                    prompt: additionalPrompt
                })
            });

            if (response.ok) {
                navigate('/');
            } else {
                console.error('Failed to create infographics');
            }
        } catch (error) {
            console.error('Error creating infographics:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBackToProductList = () => {
        navigate('/');
    };

    return (
        <div className="container mt-4">
            <div className="d-flex align-items-center mb-3">
                <Button variant="secondary" onClick={handleBackToProductList} className="me-2">
                    &larr; к списку продуктов
                </Button>
                <h2>Создать инфографику</h2>
            </div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Шаблон</Form.Label>
                    <Select
                        value={selectedTemplate}
                        onChange={setSelectedTemplate}
                        options={templates}
                        isClearable
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Дополнительный промпт</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={additionalPrompt}
                        onChange={(e) => setAdditionalPrompt(e.target.value)}
                    />
                </Form.Group>
                <Button type="submit" disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Создать инфографику'}
                </Button>
            </Form>
            <div className="mt-4">
                <h4>Выбранные товары</h4>
                <ul>
                    {selectedProducts.map((sku) => (
                        <li key={sku}>{sku}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default InfographicsCreator;
