// src/store.ts
import { set as indexDatabaseSet, get as indexDatabaseGet } from 'idb-keyval'; // Aliasing the methods
import { fetchFigmaDocument } from './figma';
import { extractPageOptions, extractTemplateOptions } from './utils';
import * as renderables from '../types/renderables';
import { z } from 'zod';
import useEditingBlockStore from '../stores/editing-block-store';
import { log } from 'console';

const getDataAtParentPath = (data: any, path: (string | number)[]): any => {
  let current = data;
  for (let i = 0; i < path.length - 1; i++) {
    if (current == null) {
      return undefined;
    }
    current = current[path[i]];
  }
  return current;
};

export async function clearDocumentData() {
  const {
    clearDocumentData
  } = useEditingBlockStore.getState();
  indexDatabaseSet('documentData', null);
  clearDocumentData();
}

export async function fetchAndStoreDocumentData() {
  const {
    selectedPage,
    selectedTemplate,
    setDocumentData,
    setPages,
    setTemplates,
    setSelectedPage,
  } = useEditingBlockStore.getState();

  let documentData = await indexDatabaseGet('documentData');
  if (!documentData) {
    const documentDataJson = await fetchFigmaDocument();
    documentData = documentDataJson;
  }
  let parsedData;
  try {
    parsedData = renderables.documentSchema.parse(documentData);
    setDocumentData(parsedData);

    const pageOptions = extractPageOptions(parsedData);
    setPages(pageOptions);
    const activePage = selectedPage || pageOptions[0];
    setSelectedPage(activePage);
    setTemplates(extractTemplateOptions(parsedData, activePage.value));
    await indexDatabaseSet('documentData', parsedData);
  } catch (parseError) {
    if (parseError instanceof z.ZodError) {
      parseError.errors.forEach(error => {
        const errorData = getDataAtParentPath(documentData, error.path);
        error['data'] = errorData;
        console.error('parseError', error);
      });
    }
    throw new Error('There is inconsistency in Figma schema take a look ☝️');
  }
}

export function parseFrame(frameData) {
  try {
    const frame = renderables.frameSchema.parse(frameData);
    return frame;
  } catch (parseError) {
    if (parseError instanceof z.ZodError) {
      parseError.errors.slice(0, 10).forEach(error => {
        const errorData = getDataAtParentPath(frameData, error.path);
        error['data'] = errorData;
        console.error('parseError', error);
      });
    }
    throw new Error('There is inconsistency in Frame schema take a look ☝️');
  }
}
