import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Form, InputGroup, Row, Col, Button, Alert } from 'react-bootstrap';
import { handleIconClick } from '../../../utils/iconUtils'; // Import the handleIconClick utility
import { fabric } from 'fabric';
import './IconEdit.css'; // Assuming you add custom CSS for skeleton

interface IconEditProps {
    object: fabric.Object;
}

const IconEdit: React.FC<IconEditProps> = ({ object }) => {
    const [searchText, setSearchText] = useState('');
    const [icons, setIcons] = useState<string[]>([]);
    const [imageLoading, setImageLoading] = useState<boolean[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const initialLoadRef = useRef(true);

    const iconsToDisplayNumber = 21;

    const handleSearch = async (query: string) => {
        if (!query.trim()) {
            setIcons([]);
            setSearchPerformed(true);
            return;
        }
        setLoading(true);
        setIcons([]); // Clear previous icons while loading new ones
        try {
            const response = await axios.get('https://app.omnifeed.ru/api/icons', { params: { query } });
            setIcons(response.data.icons_thumbnails.slice(0, iconsToDisplayNumber)); // Display the first 10 results
            setImageLoading(new Array(response.data.icons_thumbnails.length).fill(true)); // Initialize loading state for each image
        } catch (error) {
            console.error('Error fetching icons:', error);
        } finally {
            setLoading(false);
            setSearchPerformed(true);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSearch(searchText);
        }
    };

    useEffect(() => {
        const initialSearchText = object.metadata?.fills[0]?.icon_description || '';
        setSearchText(initialSearchText);
        setIcons([]); // Clear icons state
        setImageLoading([]); // Clear image loading state
        setSearchPerformed(false); // Reset search performed flag
        initialLoadRef.current = true; // Mark as initial load
        handleSearch(initialSearchText); // Trigger search immediately when object changes

        // Clear debounce timeout if set
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [object]);

    useEffect(() => {
        if (initialLoadRef.current) {
            initialLoadRef.current = false; // Reset the initial load flag
            return; // Skip the first effect run triggered by the initial searchText setting
        }

        if (searchText.trim() === '') return;

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            handleSearch(searchText);
        }, 1000);

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [searchText]);

    const handleImageLoad = (index: number) => {
        setImageLoading(prevState => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
        });
    };

    return (
        <div className="p-3">
            <h5>Icon Edit</h5>
            <p>{object.metadata?.name}</p>
            <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Search icons"
                />
            </InputGroup>
            {!loading && icons.length === 0 && searchPerformed && searchText.trim() && (
                <Alert variant="warning">No icons found</Alert>
            )}
            <Row>
                {icons.map((icon, index) => (
                    <Col key={index} md={4} className="mb-3">
                        <Button
                            variant="outline-secondary"
                            className="w-100 h-100 p-1"
                            style={{ padding: '0 !important', border: 'none !important' }}
                            onClick={() => handleIconClick(object, icon, searchText)}
                        >
                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                {imageLoading[index] && (
                                    <div className="skeleton-loader" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
                                )}
                                <img
                                    src={icon}
                                    alt={`Icon ${index}`}
                                    className="img-fluid"
                                    style={{ width: '100% !important', height: '100% !important', objectFit: 'contain !important', visibility: imageLoading[index] ? 'hidden' : 'visible' }}
                                    onLoad={() => handleImageLoad(index)}
                                />
                            </div>
                        </Button>
                    </Col>
                ))}
                {loading && Array.from({ length: iconsToDisplayNumber }).map((_, index) => (
                    <Col key={index} md={4} className="mb-3">
                        <div className="skeleton-loader"></div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default IconEdit;
