import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IDBStorage } from '../services/IDBStorage';

interface EditingBlockState {
  frames: any | null;
  images: any | null;
  setFramesAndImages: (data: any) => void;
}

const idbStorage = new IDBStorage();

const useEditingBlockStore = create<EditingBlockState>()(
  persist(
    (set, get) => ({
      frames: [],
      images: [],
      setFramesAndImages: (framesAndImages) => set({ frames: framesAndImages['frames'], images: framesAndImages['images'] }),
    }),
    {
      name: 'editing-block-storage',
      storage: idbStorage,
      partialize: (state) => ({
        frames: state.frames,
        images: state.images,
      }),
    }
  )
);

export default useEditingBlockStore;
