// src/components/App.tsx

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../fabric-extentions/fabric-extensions';
import NotFound from './NotFound';
import ProductList from './infographics-list/ProductList';
import InfographicsEditor from './infographics-editing/InfographicsEditor';
import InfographicsCreator from './infographics-creation/InfographicsCreator';
import SandboxInfographicsEditor from './sandbox/SandboxInfographicsEditor';
import AddProducts from './products-adding/AddProducts';
import EditingMenu from './infographics-editing/EditingMenu';
import { setUserInteracting } from '../stores/user-interaction-store';

const App: React.FC = () => {
  useEffect(() => {
    const handleMouseDown = () => setUserInteracting(true);
    const handleMouseUp = () => setTimeout(() => setUserInteracting(false), 100);

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <Router>
      <div className="app-container">
        <EditingMenu />
        <div className="main-content">
          <Routes>
            <Route path="/sandbox" element={<SandboxInfographicsEditor />} />
            <Route path="/edit-infographics" element={<InfographicsEditor />} />
            <Route path="/create-infographics" element={<InfographicsCreator />} />
            <Route path="/add-products" element={<AddProducts />} />
            <Route path="/" element={<ProductList />} />
            <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
