import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { fabric } from 'fabric';

interface CanvasState {
    canvases: Map<string, fabric.Canvas[]>;
    activeCanvas: fabric.Canvas | null;
    unsavedChanges: boolean;
    addCanvas: (sku: string, slideIndex: number, canvas: fabric.Canvas) => void;
    getCanvas: (sku: string, slideIndex: number) => fabric.Canvas | undefined;
    removeCanvasesBlock: (sku: string) => void;
    setActiveCanvas: (canvas: fabric.Canvas) => void;
    setUnsavedChanges: (unsaved: boolean) => void;
}

export const useCanvasStore = create<CanvasState>()(
    devtools((set, get) => ({
        canvases: new Map(),
        activeCanvas: null,
        unsavedChanges: false,

        addCanvas: (sku, slideIndex, canvas) => set(state => {
            const newCanvases = new Map(state.canvases);
            const blockCanvases = newCanvases.get(sku) ? [...(newCanvases.get(sku) as fabric.Canvas[])] : [];
            blockCanvases[slideIndex] = canvas;
            newCanvases.set(sku, blockCanvases);
            return { canvases: newCanvases };
        }),

        getCanvas: (sku, slideIndex) => {
            const blockCanvases = get().canvases.get(sku);
            return blockCanvases ? blockCanvases[slideIndex] : undefined;
        },

        removeCanvasesBlock: (sku) => set(state => {
            const newCanvases = new Map(state.canvases);
            newCanvases.delete(sku);
            return { canvases: newCanvases };
        }),

        setActiveCanvas: (canvas) => set(() => {
            return { activeCanvas: canvas };
        }),

        setUnsavedChanges: (unsaved) => set({ unsavedChanges: unsaved }),
    }), { name: "CanvasStore" })
);
