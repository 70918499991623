import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useProductListStore } from '../../stores/product-list-store';

const ProductListFilter: React.FC = () => {
    const { productsFilter, setProductsFilter, fetchProducts, page } = useProductListStore();
    const [localFilter, setLocalFilter] = useState(productsFilter);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [isMounting, setIsMounting] = useState(true);

    useEffect(() => {
        if (isMounting) {
            // Avoid making a request on the initial mount
            setIsMounting(false);
            return;
        }

        // Clear the previous timeout if a new input is supplied
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        // Set a new timeout for debouncing
        const newTimeout = setTimeout(() => {
            setProductsFilter(localFilter);
            fetchProducts(page, localFilter);
        }, 1000);

        setTypingTimeout(newTimeout);

        // Clean up timeout on unmount or new timeout
        return () => clearTimeout(newTimeout);
    }, [localFilter, setProductsFilter, fetchProducts, page]);

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            // Clear any existing timeout
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
            // Immediately submit the filter
            setProductsFilter(localFilter);
            fetchProducts(page, localFilter);
        }
    };

    return (
        <InputGroup className="mb-3">
            <FormControl
                placeholder="Фильтр продуктов"
                value={localFilter}
                onChange={(e) => setLocalFilter(e.target.value)}
                onKeyPress={handleKeyPress}
            />
        </InputGroup>
    );
};

export default ProductListFilter;
