// ProductListRow.tsx

import React, { useState } from 'react';
import { Product, useProductListStore } from '../../stores/product-list-store';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Skeleton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { log } from 'console';

interface ProductListRowProps {
    product: Product;
}

const ProductListRow: React.FC<ProductListRowProps> = ({ product }) => {
    const { selectedProducts, toggleSelectProduct, deselectAllProducts, deleteInfographics } = useProductListStore();
    const isSelected = selectedProducts.includes(product.sku);
    const navigate = useNavigate();

    const handleActionClick = (sku: string, path: string) => {
        if (!isSelected) {
            deselectAllProducts();
            toggleSelectProduct(sku);
        }
        navigate(path);
    };

    const handleDeleteClick = async (sku: string) => {
        if (!isSelected) {
            deselectAllProducts();
            toggleSelectProduct(sku);
        }
        await deleteInfographics();
    };

    const renderInfographic = () => {
        if (!product.infographic || product.infographic.length === 0) {
            return (
                <div className="text-center">
                    <p>Нет инфографики</p>
                </div>
            );
        }
        return (
            <div className="text-center">
                <p>{product.infographic.status}</p>
            </div>
        );
    };

    const renderActionButton = () => {
        if (!product.infographic || product.infographic.length === 0) {
            return (
                <button className="btn btn-link text-success p-0" onClick={() => handleActionClick(product.sku, '/create-infographics')}>
                    <AddIcon />
                </button>
            );
        }

        return (
            <button className="btn btn-link text-success p-0" onClick={() => handleActionClick(product.sku, '/edit-infographics')}>
                <EditIcon />
            </button>
        );
    };

    return (
        <tr className={isSelected ? 'table-primary' : ''}>
            <td
                className="text-center align-middle"
                onClick={() => toggleSelectProduct(product.sku)}
                style={{ cursor: 'pointer' }}
            >
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleSelectProduct(product.sku)}
                    className="form-check-input"
                    style={{ pointerEvents: 'none' }} // Prevent the checkbox from being directly clickable
                />
            </td>
            <td className="align-middle">{product.sku}</td>
            <td className="align-middle">{product.data.title}</td>
            <td className="text-center align-middle">
                <ImageWithPlaceholder src={product.thumbnail} alt={product.data.title} />
            </td>
            <td className="align-middle">{renderInfographic()}</td>
            <td className="text-center align-middle">
                <div className="btn-group">
                    {renderActionButton()}
                    <button className="btn btn-link text-danger p-0" onClick={() => handleDeleteClick(product.sku)}>
                        <DeleteIcon />
                    </button>
                </div>
            </td>
        </tr>
    );
};

const ImageWithPlaceholder: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div style={{ width: '50px', height: '50px', position: 'relative' }}>
            {!loaded && <Skeleton variant="rectangular" width={50} height={50} />}
            <img
                src={src}
                alt={alt}
                onLoad={() => setLoaded(true)}
                style={{
                    display: loaded ? 'block' : 'none',
                    width: '50px',
                    height: '50px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                }}
            />
        </div>
    );
};

export default ProductListRow;
