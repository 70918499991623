import { get, set, del } from 'idb-keyval';
import { StateStorage } from 'zustand/middleware';

export class IDBStorage implements StateStorage {

    async getItem(key: string): Promise<any> {
        const value = await get(key);
        return value === undefined ? null : value;
    }

    async setItem(key: string, value: any): Promise<void> {
        await set(key, value);
    }

    async removeItem(key: string): Promise<void> {
        await del(key);
    }
}
