// src/components/InfographicsEditor.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fabric } from 'fabric';
import { useProductListStore } from '../../stores/product-list-store';
import EditingBlock from './EditingBlock';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { log } from 'console';
import { downloadAllInfographics } from '../../utils/downloadUtils';
import { useCanvasStore } from '../../stores/canvas-store';
import { toJSONDatalessWithSubresources } from '../../fabric-extentions/fabric-extensions';
import { InfographicsAndProductData } from '../../types/InfographicsAndProductData';
import { setupCopyPasteDeleteListeners } from '../../event-listeners/copy-paste-delete-listeners';

const InfographicsEditor: React.FC = () => {
    const { selectedProducts } = useProductListStore();
    const [data, setData] = useState<InfographicsAndProductData[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [failedSkus, setFailedSkus] = useState<string[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();
    const { canvases, unsavedChanges, setUnsavedChanges } = useCanvasStore(state => ({
        canvases: state.canvases,
        unsavedChanges: state.unsavedChanges,
        setUnsavedChanges: state.setUnsavedChanges
    }));

    useEffect(() => {
        const cleanup = setupCopyPasteDeleteListeners();
        return () => {
            cleanup();
            setUnsavedChanges(false);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedProducts.length === 0) {
                setData([]);
                setTotalPages(1);
                return;
            }

            setLoading(true);

            try {
                const limit = 10;
                const response = await fetch(`/api/get-infographics-for-editing/?page=${page}&limit=${limit}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ skus: selectedProducts }) // Using selected SKUs
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                log('response', result);
                setData(result.data);
                setTotalPages(result.totalPages);
            } catch (error) {
                console.error('Не удалось получить данные:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [page, selectedProducts]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (unsavedChanges) {
                const message = 'Изменения не сохранены';
                e.preventDefault();
                e.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]);

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handleBackToProductList = () => {
        if (unsavedChanges) {
            setShowModal(true);
        } else {
            navigate('/');
        }
    };

    const handleDownloadAll = () => {
        downloadAllInfographics(canvases);
    };

    const handleSaveAll = async () => {
        setIsSaving(true); // Start saving
        const infographics: { [key: string]: any[] } = {};
        const failedSkus: string[] = [];

        canvases.forEach((canvasArray, sku) => {
            try {
                infographics[sku] = canvasArray.map((canvas, idx) => {
                    var jsonDataless = toJSONDatalessWithSubresources(canvas);
                    if (jsonDataless.objects.length == 0) {
                        console.error('Error in canvas', canvas);
                        throw new Error('Canvas has no elements')
                    }
                    return jsonDataless;
                });
            } catch (error) {
                console.error(`Failed to process SKU ${sku}:`, error);
                failedSkus.push(sku);
            }
        });

        if (failedSkus.length > 0) {
            setFailedSkus(failedSkus);
            alert(`Некоторые продукты не были сохранены (${failedSkus.length} штук из ${canvases.size}). Проверьте консоль для получения дополнительной информации.`);
        }

        try {
            const response = await fetch('/api/save-infographics', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ infographics })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Save response:', result);
            setUnsavedChanges(false);
        } catch (error) {
            console.error('Не удалось сохранить инфографику:', error);
        } finally {
            setIsSaving(false); // End saving
        }
    };

    const handleSaveAndExit = () => {
        setIsSaving(true); // Start saving
        handleSaveAll().then(() => {
            setIsSaving(false); // End saving after saveAll is complete
            navigate('/');
        });
    };

    return (
        <div className="container mt-4">
            <div className="d-flex align-items-center mb-3">
                <Button variant="secondary" onClick={handleBackToProductList} className="me-2">
                    &larr; к списку продуктов
                </Button>
                <h2>Редактор инфографики</h2>
                <Button variant="primary" className="ms-auto" onClick={handleDownloadAll}>
                    Скачать для всех продуктов
                </Button>
                <Button variant="success" className="ms-2" onClick={handleSaveAll} disabled={isSaving}>
                    {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Сохранить'}
                </Button>
            </div>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            ) : data.length === 0 ? (
                <p>Нет данных для отображения. Выберите продукты для просмотра их инфографики.</p>
            ) : (
                data.map((item, index) => (
                    <EditingBlock
                        key={index}
                        infographics={item.infographics}
                        product_data={item.product_data}
                    />
                ))
            )}
            <div className="d-flex justify-content-between mt-4">
                <Button onClick={handlePrevPage} disabled={page === 1}>Назад</Button>
                <span>Страница {page} из {totalPages}</span>
                <Button onClick={handleNextPage} disabled={page === totalPages}>Дальше</Button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Изменения не сохранены</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>У вас есть несохраненные изменения. Вы хотите сохранить их перед выходом?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => navigate('/')}>
                        Не сохранять
                    </Button>
                    <Button variant="primary" onClick={handleSaveAndExit}>
                        Сохранить и выйти
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InfographicsEditor;
