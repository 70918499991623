import React from 'react';
import SandboxForm from './SandboxForm'; // Adjust the import path as necessary
import EditingBlock from '../infographics-editing/EditingBlock'; // Adjust the import path as necessary
import useEditingBlockStore from '../../stores/editing-block-store'; // Adjust the import path as necessary

const SandboxInfographicsEditor: React.FC = () => {
    const { frames, images } = useEditingBlockStore();

    const hasData = frames && frames.length > 0 && images && images.length > 0;

    // Dummy data for status, sku, and product_title for demonstration
    const status = hasData ? "CREATED" : "NOT_CREATED";
    const sku = "dummy-sku";
    const product_title = "Dummy Product Title";

    return (
        <div>
            <SandboxForm />
            {hasData && (
                <EditingBlock
                    status={status}
                    sku={sku}
                    product_title={product_title}
                    data={{ frames, images, meta: { color_schemas: [], icons: null } }}
                />
            )}
        </div>
    );
};

export default SandboxInfographicsEditor;
