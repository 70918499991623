import { fabric } from 'fabric';
import ImageService from '../services/ImageService';
import { log } from 'console';
import FontService from '../services/FontService';
import './group-extention';


// Cast fabric to any to add custom classes without TypeScript errors
var Fabric: any = fabric;

// Utility function to extend Fabric classes with metadata handling
function extendFabricClass(klass: any) {
    // Extend toObject method to include metadata, selectable, and evented
    klass.prototype.toObject = (function (toObject: Function) {
        return function (this: any, propertiesToInclude?: string[]) {
            return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                metadata: this.metadata || {},
                selectable: this.selectable,
                evented: this.evented,
            });
        };
    })(klass.prototype.toObject);

    // Extend fromObject method to handle metadata deserialization
    if (klass.fromObject) {
        klass.fromObject = (function (fromObject: Function) {
            return function (object: any, callback: (obj: any) => void) {
                fromObject.call(this, object, function (instance: any) {
                    instance.metadata = object.metadata || {};
                    instance.selectable = object.selectable;
                    instance.evented = object.evented;
                    callback && callback(instance);
                });
            };
        })(klass.fromObject);
    }
}

// Classes to be extended
const classesToExtend = [
    Fabric.Object, // Base class for all Fabric objects
    Fabric.Gradient,
    Fabric.Canvas,
    Fabric.Group,
    Fabric.Path,
    Fabric.Textbox
];

// Apply extension to each class
classesToExtend.forEach(extendFabricClass);

// Utility function to extend the Fabric Pattern class with imageRef handling
function extendFabricPatternClass(klass: any) {
    // Extend toObject method to include imageRef
    klass.prototype.toObject = (function (toObject: Function) {
        return function (this: any) {
            return fabric.util.object.extend(toObject.call(this), {
                imageRef: this.imageRef || null
            });
        };
    })(klass.prototype.toObject);

    // Extend fromObject method to handle imageRef deserialization
    if (klass.fromObject) {
        klass.fromObject = (function (fromObject: Function) {
            return function (this: any, object: any, callback: (obj: any) => void) {
                fromObject.call(this, object, function (instance: any) {
                    instance.imageRef = object.imageRef || null;
                    callback && callback(instance);
                });
            };
        })(klass.fromObject);
    }
}

// Apply extension to the Fabric Pattern class
extendFabricPatternClass(fabric.Pattern);


export function toJSONDatalessWithSubresources(canvas: fabric.Canvas) {
    const datalessJSON = canvas.toDatalessJSON(['selectable', 'evented']);
    console.log('datalessJSON', datalessJSON)
    const savedState = JSON.parse(JSON.stringify(datalessJSON));

    const processPattern = (obj: any) => {
        // if (obj.fill && obj.fill.source) {
        //     // Replace pattern source with image URL
        //     const sourceUrl = group.metadata.fills[0].imageRef;


        //     obj.fill.patternSourceCanvas = obj.fill.sourceUrl; // Replace with your image URL logic
        // }

        // console.log('obj.metadata.fills', obj.metadata.name, obj.metadata.fills)
        if (obj.objects && obj.objects.length == 1
            && obj.metadata && obj.metadata.fills && obj.metadata.fills.length > 0 && obj.metadata.fills[0].imageRef) {

            delete obj.objects[0].fill.source;
            // obj.objects[0].fill.imageRef = obj.metadata.fills[0].imageRef
            // console.log('after', obj.metadata.name, obj.metadata.fills[0].imageRef, obj.objects[0].fill.imageRef)
        }

        if (obj.objects) {
            obj.objects.forEach(processPattern);
        }
    };

    savedState.objects.forEach(processPattern);
    return savedState;
}

export function loadSubresources(canvas: fabric.Canvas, parsedState: any) {
    loadPatternImages(canvas);
    processTextFonts(canvas);
}

// Function to load canvas state from JSON and restore pattern images
function loadPatternImages(canvas: fabric.Canvas) {
    const restorePatternImage = (object: fabric.Object) => {
        if (object.fill && (object.fill as any).imageRef) {
            const imageRef = (object.fill as any).imageRef;
            ImageService.getImage(imageRef).then((patternImg: HTMLImageElement) => {
                if (!patternImg) {
                    throw new Error(`Image with reference ${JSON.stringify(imageRef)} not found`);
                }
                const patternSourceCanvas = document.createElement('canvas');
                patternSourceCanvas.width = patternImg.width;
                patternSourceCanvas.height = patternImg.height;
                const patternCtx = patternSourceCanvas.getContext('2d');
                patternCtx!.drawImage(patternImg, 0, 0, patternImg.width, patternImg.height);

                object.set('fill', new fabric.Pattern({
                    source: patternSourceCanvas as any,
                    repeat: 'no-repeat',
                    imageRef: imageRef,
                    patternTransform: (object.fill as any).patternTransform || null
                }));

                canvas.renderAll();
            });
        }
    };

    const processObjects = (objects: fabric.Object[]) => {
        objects.forEach((object) => {
            restorePatternImage(object);
            if (object instanceof fabric.Group) {
                processObjects(object.getObjects());
            }
        });
    };

    processObjects(canvas.getObjects());
    canvas.renderAll();
}

function getAllTextObjects(objects: fabric.Object[]): fabric.Text[] {
    let textObjects: fabric.Text[] = [];

    for (const obj of objects) {
        if (obj.type === 'text' || obj.type === 'i-text' || obj.type === 'textbox') {
            textObjects.push(obj as fabric.Text);
        } else if (obj.type === 'group') {
            textObjects = textObjects.concat(getAllTextObjects((obj as fabric.Group).getObjects()));
        }
    }

    return textObjects;
}

async function processTextFonts(canvas: fabric.Canvas) {
    const textObjects = getAllTextObjects(canvas.getObjects());
    const fontPromises = textObjects.map(async (textObj) => {
        const fontFamily = textObj.fontFamily;
        if (fontFamily) {
            await FontService.getFont(fontFamily);
            textObj.set('fontFamily', fontFamily); // Update fontFamily to ensure it's applied
            textObj.dirty = true;
        }

    });

    await Promise.all(fontPromises);
    canvas.renderAll(); // Rerender the canvas to apply all font changes
    console.log('All fonts processed and canvas rerendered');
}
