import { log } from "console";
import FontService from "../services/FontService";

export function findElementByName(element, searchName: string): any | null {
  if (element.name === searchName) {
    return element;
  }
  if (element.children) {
    for (const child of element.children) {
      const found = findElementByName(child, searchName);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

// src/utils.ts

export interface OptionType {
  value: string;
  label: string;
}

// Function to extract page options from the data
export const extractPageOptions = (data: any): OptionType[] => {
  if (data && Array.isArray(data.children)) {
    return data.children.map((child: any) => ({
      value: child.name,
      label: child.name.charAt(0).toUpperCase() + child.name.slice(1)
    }));
  }
  return [];
};

// Function to extract template options from the data based on the selected page
export const extractTemplateOptions = (data: any, selectedPageName: string): OptionType[] => {
  if (!data || !Array.isArray(data.children)) {
    return [];
  }
  const selectedPage = data.children.find((child: any) => child.name === selectedPageName);
  if (!selectedPage || !Array.isArray(selectedPage.children)) {
    return [];
  }
  return selectedPage.children.map((child: any) => ({
    value: child.name,
    label: child.name.charAt(0).toUpperCase() + child.name.slice(1)
  }));
};

export async function uploadImageToServer(imageElement: HTMLImageElement): Promise<string> {
  const blob = await fetch(imageElement.src).then(response => response.blob());
  const fileType = blob.type;
  const extension = fileType.split('/')[1]; // Extract the file extension from the MIME type
  const file = new File([blob], `uploaded_image.${extension}`, { type: fileType });
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch('/api/images', {
    method: 'PUT',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Failed to upload image');
  }

  const data = await response.json();
  return data.uuid;
}

