// src/editing-components/ImageEdit.tsx

import React, { useState } from 'react';
import { fabric } from 'fabric';

interface ImageEditProps {
    object: fabric.Object;
}

const ImageEdit: React.FC<ImageEditProps> = ({ object }) => {
    const group = object as fabric.Group & { blurValue?: number };
    const [blurValue, setBlurValue] = useState(group.blurValue || 0);

    const handleBlurValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setBlurValue(value);
        group.blurValue = value;
        group.dirty = true;
        group.canvas?.renderAll();
    };

    return (
        <div>
            <h5>Image Edit</h5>
            <p>{group.metadata?.name}</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label style={{ margin: 0 }}>Blur</label>
                <input
                    type="range"
                    min="0"
                    max="10"
                    step="0.01"
                    value={blurValue}
                    onChange={handleBlurValueChange}
                />
            </div>
        </div>
    );
};

export default ImageEdit;
