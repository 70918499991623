// src/stores/user-interaction-store.ts
import create from 'zustand';

interface UserInteractionState {
    userInteracting: boolean;
    setUserInteracting: (interacting: boolean) => void;
}

export const useUserInteractionStore = create<UserInteractionState>(set => ({
    userInteracting: false,
    setUserInteracting: (interacting: boolean) => set({ userInteracting: interacting })
}));

export const setUserInteracting = (interacting: boolean) => {
    const store = useUserInteractionStore.getState();
    store.setUserInteracting(interacting);
};
