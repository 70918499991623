import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Alert } from 'react-bootstrap';
import { log } from 'console';

const AddProducts: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [googleDocsLink, setGoogleDocsLink] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
            setGoogleDocsLink(''); // Clear the Google Docs link if a file is selected
        }
    };

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGoogleDocsLink(event.target.value);
        setFile(null); // Clear the file input if a link is provided
    };

    const downloadCSVFromGoogleDocs = async (link: string) => {
        const fileIdMatch = link.match(/\/d\/([^\/?]+)/);
        const fileId = fileIdMatch?.[1];
        if (!fileId) {
            throw new Error('Invalid Google Docs link');
        }

        const url = new URL(link);
        const gid = url.searchParams.get('gid');

        let exportLink = `https://docs.google.com/spreadsheets/d/${fileId}/export?format=csv`;
        if (gid) {
            exportLink += `&gid=${gid}`;
        }

        const response = await fetch(exportLink);
        if (!response.ok) {
            throw new Error('Failed to download CSV from Google Docs');
        }

        return new Blob([await response.text()], { type: 'text/csv' });
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setError(null);

        let csvFile: File | null = file;

        if (googleDocsLink) {
            try {
                const blob = await downloadCSVFromGoogleDocs(googleDocsLink);
                csvFile = new File([blob], 'google_docs.csv', { type: 'text/csv' });
            } catch (error: any) {
                setError('Failed to download CSV from Google Docs: ' + error.message);
                return;
            }
        }

        if (!csvFile) {
            setError('Please select a file or provide a Google Docs link.');
            return;
        }

        const formData = new FormData();
        formData.append('file', csvFile);

        try {
            const response = await fetch('/api/upload_csv/', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                navigate('/');
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to upload file.');
            }
        } catch (error) {
            setError('Failed to upload file.');
        }
    };

    return (
        <div className="container mt-4">
            <h2>Загрузить товары</h2>
            {error && <Alert variant="danger" style={{ color: 'red' }}>{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Выберите CSV файл</Form.Label>
                    <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
                </Form.Group>
                <Form.Group controlId="formLink" className="mb-3">
                    <Form.Label>Или введите ссылку на Google Docs</Form.Label>
                    <Form.Control type="text" value={googleDocsLink} onChange={handleLinkChange} placeholder="https://docs.google.com/spreadsheets/d/..." />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Загрузить товары
                </Button>
            </Form>
        </div>
    );
};

export default AddProducts;
