import { fabric } from 'fabric';
import * as renderables from '../types/renderables'; // Import all from renderables
import { getImage } from '../utils/figma';
import { log } from 'console';
import ImageService from '../services/ImageService';
import FontService from '../services/FontService';


var blendModeMap = {
    "PASS_THROUGH": "source-over",
    "NORMAL": "source-over",
    "LINEAR_BURN": null,  // Not supported
    "MULTIPLY": "multiply",
    "DARKEN": "darken",
    "COLOR_BURN": "color-burn",
    "COLOR_DODGE": "color-dodge",
    "LINEAR_DODGE": null,  // Not supported
    "SCREEN": "screen",
    "LIGHTEN": "lighten",
    "OVERLAY": "overlay",
    "EXCLUSION": "exclusion",
    "DIFFERENCE": "difference",
    "HARD_LIGHT": "hard-light",
    "SOFT_LIGHT": "soft-light",
    "HUE": "hue",
    "LUMINOSITY": "luminosity",
    "COLOR": "color",
    "SATURATION": "saturation"
};

// Utility function to convert color to RGBA string
const colorToRgba = (color: renderables.Color): string => {
    return `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, ${color.a})`;
};

// Function to create a gradient fill
const createGradientFill = (paint: any, size: any) => {
    if (!paint.gradientHandlePositions || paint.gradientHandlePositions.length < 2) {
        throw new Error('Invalid gradient handle positions');
    }

    if (!paint.gradientStops || paint.gradientStops.length === 0) {
        throw new Error('No gradient stops provided');
    }

    const gradientOptions = {
        type: 'linear',
        gradientUnits: 'percentage',
        coords: {
            x1: paint.gradientHandlePositions[0].x,
            y1: paint.gradientHandlePositions[0].y,
            x2: paint.gradientHandlePositions[1].x,
            y2: paint.gradientHandlePositions[1].y,
        },
        colorStops: paint.gradientStops.map((stop: any, index: number) => {
            if (typeof stop.position !== 'number' || !stop.color) {
                throw new Error(`Invalid gradient stop at index ${index}`);
            }
            return {
                offset: stop.position,
                color: colorToRgba(stop.color)
            };
        })
    };

    return new fabric.Gradient(gradientOptions);
};

function applyRotationToPattern(vectorSize: { x: number; y: number }, rotation: number, scaleX: number = 1, scaleY: number = 1, translateX: number = 100, translateY: number = 50): number[] {
    // Convert rotation from degrees to radians
    const rotationRadians = rotation * (Math.PI / 180);

    // Calculate the rotation components
    const cosTheta = Math.cos(rotationRadians);
    const sinTheta = Math.sin(rotationRadians);

    // Calculate the center of the element
    const centerX = vectorSize.x / 2;
    const centerY = vectorSize.y / 2;

    // Apply the rotation and scale to the matrix
    const a = scaleX * cosTheta;
    const b = scaleX * sinTheta;
    const c = -scaleY * sinTheta;
    const d = scaleY * cosTheta;

    // Calculate the translation to rotate around the center
    const tx = translateX + centerX - centerX * cosTheta + centerY * sinTheta;
    const ty = translateY + centerY - centerX * sinTheta - centerY * cosTheta;

    return [
        a, b,
        c, d,
        tx, ty
    ];
}


// Function to resize the image and return the pattern transform matrix
const getPatternTransform = (paint: renderables.Paint, element: renderables.Vector, imageElement: HTMLImageElement) => {
    let scaleX = 1;
    let scaleY = 1;
    let translateX = 0;
    let translateY = 0;

    const scaleMode = paint.scaleMode || 'FIT';
    const optimizedScale = 1;//element.optimized_scale;
    const vectorSize = element.size!;
    const isImageRotatedBy90 = (paint.rotation ?? 0) % 180 != 0;

    var newDimensionX = 100;
    var newDimensionY = 100;


    switch (scaleMode) {
        case 'FIT':
        case 'FILL':
            const scaleXNeededToFit = vectorSize.x / (isImageRotatedBy90 ? imageElement.height : imageElement.width);
            const scaleYNeededToFit = vectorSize.y / (isImageRotatedBy90 ? imageElement.width : imageElement.height);

            // const scaleXNeededToFit = vectorSize.x / imageElement.width;
            // const scaleYNeededToFit = vectorSize.y / imageElement.height;

            const scale = scaleMode === 'FIT' ? Math.min(scaleXNeededToFit, scaleYNeededToFit)
                : Math.max(scaleXNeededToFit, scaleYNeededToFit);
            scaleX = scaleY = scale

            newDimensionX = imageElement.width * scaleX
            newDimensionY = imageElement.height * scaleY

            translateX = (vectorSize.x - imageElement.width * scaleX) / 2;
            translateY = (vectorSize.y - imageElement.height * scaleY) / 2;
            break;
        case 'STRETCH': {
            console.error('TODO: IMPLEMENT')
            newDimensionX = vectorSize.x * optimizedScale;
            newDimensionY = vectorSize.y * optimizedScale;
            scaleX = newDimensionX / imageElement.width;
            scaleY = newDimensionY / imageElement.height;

            const it = paint.imageTransform || [[1, 0, 0], [0, 1, 0]];
            const subMatrix2x2 = [it[0][0], it[0][1], it[1][0], it[1][1]];
            const invSubMatrix2x2 = fabric.util.invertTransform(subMatrix2x2);

            return [
                invSubMatrix2x2[0] * scaleX, invSubMatrix2x2[1] * scaleY,
                invSubMatrix2x2[2] * scaleX, invSubMatrix2x2[3] * scaleY,
                -it[0][2] * vectorSize.x * invSubMatrix2x2[0], -it[1][2] * vectorSize.y * invSubMatrix2x2[3]
            ];
        }
        default:
            throw new Error(`No implementation for mode ${scaleMode}`);
    }

    var result = applyRotationToPattern({ x: newDimensionX, y: newDimensionY }, paint.rotation, scaleX, scaleY, translateX, translateY)
    // log(element.name, result)
    // result = [scaleX, 0, 0, scaleY, translateX, translateY];
    // log(element.name, result)
    return result;
};

// Function to get fill style from paint object
const get_fabric_fill = async (paint: renderables.Paint, element: renderables.Mixed) => {
    switch (paint.type) {
        case 'NONE':
            return 'transparent';
        case 'SOLID':
            return paint.color ? colorToRgba(paint.color) : 'transparent';
        case 'GRADIENT_LINEAR':
            return createGradientFill(paint, element.size);
        case 'IMAGE':
            // Load the image and resize it
            if (!paint.imageRef) {
                return 'transparent';
            }
            const imageElement: HTMLImageElement = await ImageService.getImage(paint.imageRef);
            if (!imageElement) {
                throw new Error(`Image with reference ${paint.imageRef} not found`);
            }
            const patternTransform = getPatternTransform(paint, element as renderables.Vector, imageElement);
            const pattern = new fabric.Pattern({
                source: imageElement as HTMLImageElement,
                patternTransform,
                repeat: 'no-repeat',
                imageRef: paint.imageRef
            });

            return pattern
        default:
            return 'transparent';
    }
};

// Function to draw shadow if it exists
const drawShadowIfExists = (object, effects) => {
    if (!effects) return;
    effects.forEach(effect => {
        if (effect.type === 'DROP_SHADOW') {
            object.set('shadow', {
                color: colorToRgba(effect.color),
                blur: effect.radius,
                offsetX: effect.offset.x,
                offsetY: effect.offset.y,
            });
        }
    });
};

// Utility function to multiply two matrices
function multiplyMatrices(a: number[], b: number[]): number[] {
    return [
        a[0] * b[0] + a[2] * b[1], // a
        a[1] * b[0] + a[3] * b[1], // b
        a[0] * b[2] + a[2] * b[3], // c
        a[1] * b[2] + a[3] * b[3], // d
        a[0] * b[4] + a[2] * b[5] + a[4], // e
        a[1] * b[4] + a[3] * b[5] + a[5]  // f
    ];
}

function applyRelativeTransforms(element: fabric.Object, relativeTransformStack: renderables.Transform[] = []): void {
    // Start with an identity matrix
    let compositeMatrix = [1, 0, 0, 1, 0, 0];


    // Combine all transformation matrices into one
    relativeTransformStack.forEach(transform => {
        const [a, c, e, b, d, f] = transform.flat();
        const currentMatrix = [a, b, c, d, e, f];
        compositeMatrix = multiplyMatrices(compositeMatrix, currentMatrix);
    });

    // Extract the components from the composite matrix
    const [a, b, c, d, e, f] = compositeMatrix;

    // Calculate the determinant to check for flipping
    const determinant = a * d - b * c;

    // Calculate scale from the matrix components, considering flips independently
    const scaleX = Math.sign(a) * Math.sqrt(a * a + b * b);
    const scaleY = Math.sign(d) * Math.sqrt(c * c + d * d);

    // Calculate rotation in degrees
    const angle = Math.atan2(b, a) * (180 / Math.PI);

    // Apply the composite transformation to the element
    element.set({
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        left: e,
        top: f,
        skewX: 0, // Reset skewing to avoid distortions
        skewY: 0,  // Reset skewing to avoid distortions,
        originX: scaleX > 0 ? 'left' : 'right',
        originY: scaleY > 0 ? 'top' : 'bottom',
    });

    element.setCoords();  // Update the element's coordinates to reflect the transformations
}


const drawStroke = async (element: renderables.Mixed, object: fabric.Object) => {
    if (element.strokes.length > 0) {
        const strokeFill = await get_fabric_fill(element.strokes[0], element);
        // Fabric typing is incorrect, this actually works with patterns and gradients
        object.set('stroke', strokeFill as unknown as string);
    }
}

// Function to draw a vector
export const drawElement = async (canvas: fabric.Canvas, element: renderables.Mixed): Promise<[fabric.Group, boolean]> => {
    const paths: fabric.Path[] = [];
    const isMask = (element as renderables.Vector).isMask;
    const isVisible = element.visible && !isMask;


    const backupPaint: renderables.Paint = {
        type: 'NONE',
        visible: true,
        opacity: 1.0,
        rotation: 0,
    };
    const fills = element.fills.length ? element.fills : [backupPaint];

    for (const geometry of element.fillGeometry ?? []) {
        const pathData = geometry.path;
        const fillType = geometry.windingRule === 'NONZERO' ? 'nonzero' : 'evenodd';

        for (const fill of fills) {
            const fabricFill = await get_fabric_fill(fill, element);
            // log(element.name, element.blendMode)
            const path = new fabric.Path(pathData, {
                fill: fabricFill,
                fillRule: fillType,
                strokeWidth: element.strokeWeight ?? 1, // Default stroke width to 1 if not specified
                strokeDashArray: element.strokes?.[0]?.dashArray ?? null, // Handle dash array if available
                strokeLineCap: element.strokes?.[0]?.lineCap ?? 'butt', // Default to 'butt' if not specified
                strokeLineJoin: element.strokes?.[0]?.lineJoin ?? 'miter', // Default to 'miter' if not specified
                strokeMiterLimit: element.strokes?.[0]?.miterLimit ?? 4, // Default to 4 if not specified
                globalCompositeOperation: blendModeMap[element.blendMode],
                visible: isVisible,
                // angle: element.rotation * (180 / Math.PI)
            });

            drawStroke(element, path);
            paths.push(path);
        }
    }

    // Create a group with paths and apply the relative transform
    const group = new fabric.Group(paths);
    group.metadata = element;
    group.opacity = 'opacity' in element ? element.opacity ?? 1 : 1;

    // Apply shadow to the group
    drawShadowIfExists(group, element.effects);

    return [group, isMask];
};

function processCharacters(characters: string | string[]): string {
    if (typeof characters === 'string') {
        return characters;
    } else if (Array.isArray(characters)) {
        return characters.map(element => element.trim()).join('\n');
    } else {
        throw new Error('Invalid input type');
    }
}

async function createTextbox(element: renderables.Text, fontFamily: string): Promise<[fabric.Textbox, renderables.Transform]> {
    const { characters, style, absoluteBoundingBox, rotation } = element;
    const {
        fontWeight,
        fontSize,
        textAlignHorizontal,
        textAlignVertical,
        letterSpacing,
        lineHeightPx,
        italic,
        fills,
        textDecoration,
        textCase
    } = style;

    const isVisible = element.visible;

    let transformedText = processCharacters(characters);
    if (textCase === 'UPPER') {
        transformedText = transformedText.toUpperCase();
    } else if (textCase === 'LOWER') {
        transformedText = transformedText.toLowerCase();
    } else if (textCase === 'TITLE') {
        transformedText = transformedText.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    const addDummyWidth = 15;

    // Function to create a fabric.Textbox for measuring
    const createTempTextbox = (text: string) => new fabric.Textbox(text, {
        fontFamily: fontFamily,
        fontWeight: fontWeight.toString(),
        fontSize: fontSize,
        textAlign: textAlignHorizontal.toLowerCase() as 'left' | 'center' | 'right' | 'justify',
        charSpacing: letterSpacing,
        lineHeight: lineHeightPx / fontSize,
        fontStyle: italic ? 'italic' : 'normal',
        width: element.size.x + addDummyWidth,
    });

    // Create a temporary textbox to calculate the text height
    const tempTextbox = createTempTextbox(transformedText);
    const textHeight = tempTextbox.calcTextHeight();

    // Calculate vertical alignment
    const containerHeight = element.size.y;
    let topPosition = 0; // Default to top alignment

    if (textAlignVertical.toLowerCase() === 'center') {
        topPosition = (containerHeight - textHeight) / 2;
    } else if (textAlignVertical.toLowerCase() === 'bottom') {
        topPosition = containerHeight - textHeight;
    }


    const additionalTransform: renderables.Transform = [[1, 0, -addDummyWidth / 2], [0, 1, topPosition]];

    // Create an object of type Paint
    const backupPaint: renderables.Paint = {
        type: 'NONE',
        visible: true,
        opacity: 1.0,
        rotation: 0,
    };

    const fill = element.fills[element.fills.length - 1] ?? backupPaint;
    const fabricFill = await get_fabric_fill(fill, element);
    return [new fabric.Textbox(transformedText, {
        width: element.size.x + addDummyWidth,
        height: element.size.y,
        fontFamily: fontFamily,
        fontWeight: fontWeight.toString(),
        fontSize: fontSize,
        textAlign: textAlignHorizontal.toLowerCase() as 'left' | 'center' | 'right' | 'justify',
        fill: fabricFill,
        charSpacing: letterSpacing,
        lineHeight: lineHeightPx / fontSize,
        fontStyle: italic ? 'italic' : 'normal',
        underline: textDecoration.toLowerCase() === 'underline',
        overline: textDecoration.toLowerCase() === 'overline',
        linethrough: textDecoration.toLowerCase() === 'line-through',
        angle: rotation || 0,
        editable: true,
        globalCompositeOperation: blendModeMap[element.blendMode],
        visible: isVisible,
        // perPixelTargetFind: true
        // backgroundColor: 'lightgrey'
    }), additionalTransform];
}


function clipObjectWithAnother(canvas: fabric.Canvas, clipperGroup: fabric.Object, targetGroup: fabric.Object): fabric.Object {
    // Extract the first child from the clipper and target groups
    log('clipperGroup', clipperGroup.metadata)
    clipperGroup.set({
        // left: 0,
        // top: 0,
        // visible: false,
        absolutePositioned: true
    })
    targetGroup.clipPath = clipperGroup

    return targetGroup;
}


async function drawText(textData: renderables.Text): Promise<[fabric.Textbox, renderables.Transform]> {
    const { style } = textData;
    const { fontPostScriptName, fontFamily } = style;

    const fontToUse = await FontService.getFont(fontFamily, fontPostScriptName || undefined);

    const [textbox, additionalTransform] = await createTextbox(textData, fontToUse);
    drawStroke(textData, textbox);
    textbox.metadata = textData;

    return [textbox, additionalTransform];
}

export const drawFrame = async (canvas: fabric.Canvas, frame: renderables.Frame,
    relativeTransformStack: renderables.Transform[] = [],
    isParentFrame: boolean = false, backgroundChild = false): Promise<[fabric.Object, fabric.Object | null]> => {

    relativeTransformStack.push(frame.relativeTransform);

    const [frameObject, isMask] = await drawElement(canvas, frame);
    frameObject.selectable = !isParentFrame;
    frameObject.evented = !isParentFrame;
    const transformToApply = frame.hotReplaceRelativeTransform != null ? frame.hotReplaceRelativeTransform : frame.relativeTransform;
    applyRelativeTransforms(frameObject, relativeTransformStack.slice(0, -1).concat([transformToApply]));
    canvas.add(frameObject);

    if (frame.name == 'background' || backgroundChild) {
        frameObject.set({
            selectable: false,
            evented: false
        })
        backgroundChild = true;
    }

    var maskObject: fabric.Object | null = frame.type == 'FRAME' ? frameObject : null;
    var clipPath: fabric.Object | null = maskObject;
    // maskObject.fill = 'red';
    // log(maskObject) 
    // Draw each child
    for (const child of frame.children) {
        let childObject: fabric.Object;
        switch (child.type) {
            case 'GROUP':
            case 'FRAME':
                [childObject, clipPath] = await drawFrame(canvas, child as renderables.Frame, relativeTransformStack, undefined,
                    backgroundChild);

                // if (maskObject) {
                //     childObject = clipObjectWithAnother(canvas, maskObject, childObject)
                //     if (clipPath)
                //         clipPath = clipObjectWithAnother(canvas, maskObject, clipPath)
                // }
                break;
            case 'VECTOR':
            case 'RECTANGLE':
            case 'ELLIPSE':
            case 'REGULAR_POLYGON':
                let isMask: boolean;
                [childObject, isMask] = await drawElement(canvas, child as renderables.Vector);
                if (child.name == 'background' || backgroundChild) {
                    childObject.set({
                        selectable: false,
                        evented: false
                    })
                }
                const optimized_bbox = child.optimized_bbox;
                var transformToApplyElement = relativeTransformStack.concat([child.relativeTransform]);
                if (optimized_bbox) {
                    transformToApplyElement = [relativeTransformStack[0], [[1, 0, optimized_bbox[0]], [0, 1, optimized_bbox[1]]]];
                }

                applyRelativeTransforms(childObject, transformToApplyElement);

                // if (maskObject) {
                //     childObject = clipObjectWithAnother(canvas, maskObject, childObject)
                // }
                // if (isMask) {
                //     maskObject = childObject;
                // }
                canvas.add(childObject);
                break;
            case 'TEXT':
                var additionalTransform: renderables.Transform;
                [childObject, additionalTransform] = await drawText(child as renderables.Text);
                const t = relativeTransformStack.concat([child.relativeTransform, additionalTransform]);
                applyRelativeTransforms(childObject, t);

                // if (maskObject) {
                //     childObject.clipPath = maskObject;
                // }

                canvas.add(childObject);
                break;
            case 'BOOLEAN_OPERATION':
            case 'COMPONENT':
                console.error(`Operator "${child.type}" is not supported for "${child.name}"`)
                break;
            default:
                throw new Error(`Unsupported child type: ${child.type}`);
        }
    }
    relativeTransformStack.pop();

    return [frameObject, clipPath];
};
