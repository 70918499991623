import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Product {
    sku: string;
    title: string;
    image: string;
    data: {
        title: string;
    };
    infographic: {
        status: string;
        sku: string;
    };
    thumbnail: string;
}

interface ProductState {
    products: Product[];
    page: number;
    totalPages: number;
    selectedProducts: string[];
    productsFilter: string;
    loading: boolean;
    error: string | null;
    setProductsFilter: (filter: string) => void;
    fetchProducts: (page: number, filter?: string) => void;
    toggleSelectProduct: (sku: string) => void;
    selectAllProducts: () => void;
    deselectAllProducts: () => void;
    deleteInfographics: () => Promise<void>;
    clearError: () => void;
}

export const useProductListStore = create<ProductState>()(
    persist(
        (set, get) => ({
            products: [],
            page: 1,
            totalPages: 1,
            selectedProducts: [],
            productsFilter: '',
            loading: false,
            error: null,
            setProductsFilter: (filter: string) => set({ productsFilter: filter }),
            fetchProducts: async (page: number, filter?: string) => {
                const currentFilter = filter !== undefined ? filter : get().productsFilter;
                set({ loading: true, error: null });
                try {
                    const response = await fetch(`/api/products-list?page=${page}&limit=100&products_filter=${currentFilter}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const data = await response.json();
                    set({
                        products: data.products,
                        page: data.page,
                        totalPages: data.totalPages,
                        loading: false,
                        error: null
                    });
                } catch (error) {
                    console.error('Failed to fetch products:', error);
                    set({ loading: false, error: 'Failed to fetch products' });
                }
            },
            toggleSelectProduct: (sku: string) => set((state) => {
                const selectedProducts = state.selectedProducts.includes(sku)
                    ? state.selectedProducts.filter((id) => id !== sku)
                    : [...state.selectedProducts, sku];
                return { selectedProducts: Array.from(new Set(selectedProducts)) };
            }),
            selectAllProducts: () => set((state) => ({
                selectedProducts: Array.from(new Set(state.products.map((product) => product.sku)))
            })),
            deselectAllProducts: () => set(() => ({
                selectedProducts: []
            })),
            deleteInfographics: async () => {
                const { selectedProducts, fetchProducts, page } = get();
                if (selectedProducts.length === 0) return;

                set({ loading: true, error: null });
                try {
                    const response = await fetch('/api/delete-infographics', {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ skus: selectedProducts })
                    });

                    if (response.ok) {
                        // Refresh the product list after deletion
                        await fetchProducts(page);
                    } else {
                        const errorData = await response.json();
                        console.error('Failed to delete infographics:', errorData.message || 'Unknown error');
                        set({ error: errorData.message || 'Failed to delete infographics' });
                    }
                } catch (error) {
                    console.error('Failed to delete infographics:', error);
                    set({ error: 'Failed to delete infographics' });
                } finally {
                    set({ loading: false, selectedProducts: [] });
                }
            },
            clearError: () => set({ error: null })
        }),
        {
            name: 'product-list-store',
            partialize: (state) => ({
                selectedProducts: state.selectedProducts,
                page: state.page,
                productsFilter: state.productsFilter,
                loading: state.loading,
                error: state.error
            }),
            getStorage: () => localStorage // using localStorage for persistence
        }
    )
);
