import React, { useEffect, useRef } from 'react';
import CanvasComponent from './CanvasComponent';
import { log } from 'console';
import { useCanvasStore } from '../../stores/canvas-store';
import { Button } from 'react-bootstrap';
import { downloadInfographicsForProduct } from '../../utils/downloadUtils';
import { InfographicsAndProductData } from '../../types/InfographicsAndProductData';

const EditingBlock: React.FC<InfographicsAndProductData> = ({ infographics, product_data }) => {
  const { status, sku, product_title, data } = infographics;
  const { title, description } = product_data.data;
  const containerRef = useRef<HTMLDivElement>(null);
  const { removeCanvasesBlock, canvases } = useCanvasStore();

  const frames = data.frames;
  const images = data.images;
  const fabricjsData = data.fabricjs_data;

  useEffect(() => {

    return () => {
      removeCanvasesBlock(sku);
    }
  }, [status, sku, product_title, data]);

  const handleDownloadForProduct = () => {
    downloadInfographicsForProduct(sku, canvases);
  };

  return (
    <div className="container mt-5" ref={containerRef}>
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
          <h4 className="mb-0">SKU: {sku} Title: {title}</h4>
          <Button variant="light" onClick={handleDownloadForProduct}>
            Скачать для этого продукта
          </Button>
        </div>
        <div className="card-body">
          <div className="canvas-container d-flex flex-row p-2">
            {frames && frames.length > 0 ? (
              frames.slice(0, 20).map((frame, index) => (
                <CanvasComponent
                  key={`canvas-${index}`}
                  sku={sku}
                  slideIndex={index}
                  frame={frame}
                  fabricjsData={fabricjsData ? fabricjsData[index] : null}
                  images={images}
                />
              ))
            ) : (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <p>No frames available</p>
                <p>{data.message ?? 'No message available'}</p>
                <p>{data.traceback ?? 'No traceback available'}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditingBlock;
