import { create } from 'zustand'
import { persist } from 'zustand/middleware';
import { IDBStorage } from '../services/IDBStorage';

interface ImageState {
    images: Record<string, Blob>;
    addImage: (ref: string, blob: Blob) => void;
    getImage: (ref: string) => Blob | undefined;
}

const idbStorage = new IDBStorage();

const useImageStore = create<ImageState>()(
    persist(
        (set, get) => ({
            images: {},
            addImage: (ref, blob) => set((state) => ({
                images: { ...state.images, [ref]: blob },
            })),
            getImage: (ref) => get().images[ref],
        }),
        {
            name: 'image-storage',
            storage: idbStorage,
            partialize: (state) => ({
                images: state.images,
            }),
        }
    )
);

export default useImageStore;
