import axios from 'axios';
import * as renderables from '../types/renderables';
import { z } from 'zod';

const figmaToken = 'figd_ePuVmf6GB8ZoFP5Yb08bd2KgKFVN5qvWWz7XkEio';
const fileId = 'PD3BpVl11QWTmgs9GMV3On';

export const fetchFigmaDocument = async (ids?: string) => {
    try {
        const baseUrl = `https://api.figma.com/v1/files/${fileId}?geometry=paths&depth=999`;
        const url = ids ? `${baseUrl}&ids=${ids}` : baseUrl;

        const response = await axios.get(url, {
            headers: {
                'X-Figma-Token': figmaToken,
            },
        });
        console.log('Response Data:', response.data['document']);
        return response.data['document'];
    } catch (error) {
        console.error('Error fetching data from Figma API:', error);
    }
};

// Helper function to load an image and return a promise that resolves with the HTMLImageElement
const loadImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (error) => reject(error);
    });
}

// Function to fetch image references and URLs from Figma API
async function fetchFigmaImageUrls(fileId: string, personalAccessToken: string): Promise<Record<string, string>> {
    const url = `https://api.figma.com/v1/files/${fileId}/images`;
    const headers = {
        "X-Figma-Token": personalAccessToken
    };

    try {
        const response = await fetch(url, { headers });

        if (response.ok) {
            const data = await response.json();
            return data.meta.images;
        } else {
            throw new Error(`Failed to retrieve images: ${response.status}`);
        }
    } catch (error) {
        console.error('Error fetching Figma file images:', error);
        throw error;
    }
}

// Utility function to recursively extract imageRefs from a Frame object
export const getFrameImageRefs = (frame: renderables.Frame): string[] => {
    const imageRefs: Set<string> = new Set();

    const extractImageRefsFromPaints = (paints: renderables.Paint[]) => {
        paints.forEach(paint => {
            if (paint.imageRef) {
                imageRefs.add(paint.imageRef);
            }
        });
    };

    const traverse = (node: renderables.Mixed) => {
        if (node.fills) {
            extractImageRefsFromPaints(node.fills);
        }
        if (node.strokes) {
            extractImageRefsFromPaints(node.strokes);
        }
        if ('children' in node && node.children) {
            node.children.forEach(child => traverse(child as renderables.Mixed));
        }
    };

    traverse(frame);

    return Array.from(imageRefs);
};

// Global map to store image references and their corresponding HTMLImageElement
const imageCache = new Map<string, HTMLImageElement>();

// Function to fetch images for all imageRefs in a Frame
export async function fetchFrameImages(frame: renderables.Frame): Promise<void> {
    const imageRefs = getFrameImageRefs(frame);

    if (imageRefs.length === 0) {
        return;
    }

    // Fetch image URLs for the collected imageRefs
    var imageUrls = await fetchFigmaImageUrls(fileId, figmaToken);

    imageUrls = Object.fromEntries(
        Object.entries(imageUrls).filter(([key]) => imageRefs.includes(key))
    );

    // Create a list of promises to load all relevant images concurrently
    const imagePromises = Object.entries(imageUrls).map(async ([imageRef, imageUrl]) => {
        const img = await loadImage(imageUrl as string);
        imageCache.set(imageRef, img);
    });

    // Wait for all images to be loaded
    await Promise.all(imagePromises);
}
// Function to get an image by its reference from the cache
export function getImage(ref: string): HTMLImageElement | undefined {
    return imageCache.get(ref);
}

export async function fetchAndParseFrame(): Promise<renderables.Frame | undefined> {
    try {
        const response = await fetch('frame2.json'); // Update the path to your JSON file
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // Validate and parse the JSON data using the frame schema
        const parsedData: renderables.Frame = renderables.frameSchema.parse(data);

        return parsedData;
    } catch (error) {
        if (error instanceof z.ZodError) {
            console.error('Validation errors:', error.errors);
        } else {
            console.error('Error:', error);
        }
        return undefined;
    }
}