import { fabric } from 'fabric';

// Define an extended interface that includes blurValue
interface IGroupExtendedOptions extends fabric.IGroupOptions {
    blurValue?: number;
}

// Create a new class that extends fabric.Group
const GroupExtended = fabric.util.createClass(fabric.Group, {
    type: 'group',

    initialize: function (objects: fabric.Object[], options: IGroupExtendedOptions = {}) {
        this.callSuper('initialize', objects, options);
        this.blurValue = options.blurValue || 0;
    },

    render: function (ctx: CanvasRenderingContext2D) {
        if (this.blurValue > 0) {
            ctx.filter = `blur(${this.blurValue}px)`;
        }

        // Call the superclass's render method
        this.callSuper('render', ctx);

        // Reset the filter after rendering
        ctx.filter = 'none';
    },

    setBlurValue: function (value: number) {
        this.blurValue = value;
        this.dirty = true; // Mark the object as needing re-render
        this.canvas && this.canvas.renderAll(); // Re-render the canvas
    },

    toObject: function (propertiesToInclude?: string[]) {
        return fabric.util.object.extend(this.callSuper('toObject', propertiesToInclude), {
            blurValue: this.blurValue
        });
    }
});

// Assign the GroupExtended class to fabric.Group
fabric.Group = GroupExtended;

// Define a fromObject method for GroupExtended
fabric.Group.fromObject = function (object: any, callback: (group: fabric.Group) => void) {
    const { objects, ...options } = object;
    fabric.util.enlivenObjects(objects, (enlivenedObjects: fabric.Object[]) => {
        const groupInstance = new fabric.Group(enlivenedObjects, options);
        callback(groupInstance);
    }, 'fabric'); // Provide 'fabric' as the namespace
};
