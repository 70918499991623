import React from 'react';
import { fabric } from 'fabric';
import { useEditingMenuStore } from '../../stores/editing-menu-storage';
import TextEdit from './editing-components/TextEdit';
import IconEdit from './editing-components/IconEdit';
import ImageEdit from './editing-components/ImageEdit';

const EditingMenu: React.FC = () => {
  const selectedObjects = useEditingMenuStore((state) => state.selectedObjects);

  const handleButtonClick = () => {
    if (selectedObjects.length === 1) {
      console.log(selectedObjects[0]);
    }
  };

  const renderEditors = () => {
    const textObjects: fabric.Textbox[] = [];
    let iconObject: fabric.Object | null = null;
    let imageObject: fabric.Object | null = null;

    selectedObjects.forEach((obj) => {
      if (obj.type === 'textbox') {
        textObjects.push(obj as fabric.Textbox);
      } else if (obj.metadata && obj.metadata.name) {
        if (obj.metadata?.name.startsWith('icon_')) {
          iconObject = obj;
        } else if (obj.metadata?.name.startsWith('image_')) {
          imageObject = obj;
        } else if (obj.metadata?.name.startsWith('mini_image_')) {
          imageObject = obj;
        }
      }
    });

    return (
      <>
        {textObjects.length > 0 && (
          <div>
            <TextEdit objects={textObjects} />
            <hr />
          </div>
        )}
        {iconObject && (
          <div>
            <IconEdit object={iconObject} />
            <hr />
          </div>
        )}
        {imageObject && (
          <div>
            <ImageEdit object={imageObject} />
            <hr />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="sidebar">
      <h4>Editing Menu</h4>
      <hr />
      {selectedObjects.length > 0 ? (
        <>
          {selectedObjects.length === 1 && (
            <button className="btn btn-primary" onClick={handleButtonClick}>
              Показать объект в консоли
            </button>
          )}
          <hr />
          {renderEditors()}
        </>
      ) : (
        <p>No objects selected</p>
      )}
    </div>
  );
};

export default EditingMenu;
