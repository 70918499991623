import { useEditingMenuStore } from '../stores/editing-menu-storage';
import { useCanvasStore } from '../stores/canvas-store';
import { useUserInteractionStore } from '../stores/user-interaction-store';
import { fabric } from 'fabric';

function addOutsideClickDeselectListener(canvas: fabric.Canvas) {
    function onDocumentMouseDown(event: MouseEvent) {
        const canvasContainer = canvas.getElement().parentNode as HTMLElement;
        const sidebar = document.querySelector('.sidebar') as HTMLElement;
        const target = event.target as Node | null;

        if (target && !canvasContainer.contains(target) && !(sidebar && sidebar.contains(target))) {
            canvas.discardActiveObject().renderAll();
        }
    }

    document.addEventListener('mousedown', onDocumentMouseDown);

    return {
        removeDocumentListener: () => document.removeEventListener('mousedown', onDocumentMouseDown),
    };
}

function addObjectSelectedListener(canvas: fabric.Canvas) {
    const store = useEditingMenuStore.getState();

    function extractObjects(objects: fabric.Object[]): fabric.Object[] {
        return objects.flatMap(obj => {
            if (obj.type === 'activeSelection') {
                return (obj as fabric.ActiveSelection).getObjects();
            }
            return [obj];
        });
    }

    function onObjectSelected(event: fabric.IEvent) {
        let selectedObjects = event.selected || [];
        selectedObjects = extractObjects(selectedObjects);
        store.setSelectedObjects(selectedObjects, canvas);
    }

    function onMouseDown(event: fabric.IEvent) {
        const target = event.target;
        const canvasStore = useCanvasStore.getState();
        canvasStore.setActiveCanvas(canvas);

        if (target) {
            const selectedObjects = extractObjects([target]);
            store.setSelectedObjects(selectedObjects, canvas);
        } else {
            store.clearSelectedObjects(canvas);
        }
    }

    function onObjectModified() {
        const { userInteracting } = useUserInteractionStore.getState();
        if (!userInteracting) {
            return; // Ignore system-initiated events
        }
        const canvasStore = useCanvasStore.getState();
        canvasStore.setUnsavedChanges(true);
    }

    canvas.on('selection:created', onObjectSelected);
    canvas.on('selection:updated', onObjectSelected);
    canvas.on('selection:cleared', () => {
        store.clearSelectedObjects(canvas);
    });
    canvas.on('mouse:down', onMouseDown);
    canvas.on('object:modified', onObjectModified);
    canvas.on('object:added', onObjectModified);
    canvas.on('object:removed', onObjectModified);

    return {
        removeObjectSelectedListener: () => {
            canvas.off('selection:created', onObjectSelected);
            canvas.off('selection:updated', onObjectSelected);
            canvas.off('selection:cleared', () => {
                store.clearSelectedObjects(canvas);
            });
            canvas.off('mouse:down', onMouseDown);
            canvas.off('object:modified', onObjectModified);
            canvas.off('object:added', onObjectModified);
            canvas.off('object:removed', onObjectModified);
        }
    };
}

export function addEventListeners(canvas: fabric.Canvas) {
    const { removeDocumentListener } = addOutsideClickDeselectListener(canvas);
    const { removeObjectSelectedListener } = addObjectSelectedListener(canvas);

    return {
        removeAllListeners: () => {
            removeDocumentListener();
            removeObjectSelectedListener();
        }
    };
}
