// ProductList.tsx

import React, { useEffect, useState } from 'react';
import { useProductListStore } from '../../stores/product-list-store';
import ProductListRow from './ProductListRow';
import ProductListFilter from './ProductListFilter';
import { Table, Button, Spinner, Modal, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ProductList: React.FC = () => {
    const { products, page, totalPages, fetchProducts, selectAllProducts, deselectAllProducts, loading, error, clearError } = useProductListStore();
    const [selectAll, setSelectAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts(page);
    }, [page, fetchProducts]);

    useEffect(() => {
        if (selectAll) {
            selectAllProducts();
        } else {
            deselectAllProducts();
        }
    }, [selectAll, selectAllProducts, deselectAllProducts]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                clearError();
            }, 5000); // Clear error after 5 seconds

            return () => clearTimeout(timer);
        }
    }, [error, clearError]);

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
    };

    const handlePrevPage = () => {
        if (page > 1) fetchProducts(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) fetchProducts(page + 1);
    };

    const handleAddProducts = () => {
        navigate('/add-products');
    };

    const handleDeleteAllProducts = async () => {
        setDeleting(true);
        try {
            const response = await fetch('/api/delete-all-products', {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchProducts(page);  // Reload the product list
                setShowModal(false);
            } else {
                const errorData = await response.json();
                alert(errorData.message || 'Failed to delete products.');
            }
        } catch (error) {
            alert('Failed to delete products.');
        } finally {
            setDeleting(false);
        }
    };

    return (
        <div className="container mt-4">
            {error && <Alert variant="danger" onClose={clearError} dismissible>{error}</Alert>}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Моя инфографика</h2>
                <div>
                    <Button variant="danger" className="me-2" onClick={() => setShowModal(true)}>Удалить все продукты</Button>
                    <Button onClick={handleAddProducts}>Добавить товары</Button>
                </div>
            </div>
            <ProductListFilter />
            {loading || deleting ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    <Table striped bordered hover className="mt-3">
                        <thead className="thead-dark">
                            <tr>
                                <th
                                    className="text-center align-middle"
                                    onClick={handleSelectAllChange}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                        className="form-check-input"
                                        style={{ pointerEvents: 'none' }} // Prevent the checkbox from being directly clickable
                                    />
                                </th>
                                <th>SKU</th>
                                <th>Название</th>
                                <th className="text-center">Фото товара</th>
                                <th>Инфографика</th>
                                <th className="text-center">Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(product => (
                                <ProductListRow key={product.sku} product={product} />
                            ))}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between">
                        <Button onClick={handlePrevPage} disabled={page === 1}>Назад</Button>
                        <span>Page {page} of {totalPages}</span>
                        <Button onClick={handleNextPage} disabled={page === totalPages}>Дальше</Button>
                    </div>
                </>
            )}

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите удаление</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы действительно хотите удалить все продукты?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleDeleteAllProducts}>
                        {deleting ? <Spinner animation="border" size="sm" /> : 'Удалить'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductList;
