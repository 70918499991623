import React, { useEffect, useRef } from 'react';
import * as renderables from '../../types/renderables';
import { drawFrame } from '../../rendering/fabric-rendering';
import { fabric } from 'fabric';
import ImageService from '../../services/ImageService';
import { addEventListeners } from '../../event-listeners/fabric-event-listeners';
import { useCanvasStore } from '../../stores/canvas-store';
import { log } from 'console';
import { loadSubresources } from '../../fabric-extentions/fabric-extensions';

interface CanvasComponentProps {
  frame: renderables.Frame;
  images: any;
  sku: string;
  slideIndex: number;
  fabricjsData?: any;
}

const CanvasComponent: React.FC<CanvasComponentProps> = ({ frame, images, sku, slideIndex, fabricjsData }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const fabricCanvasRef = useRef<fabric.Canvas | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { addCanvas } = useCanvasStore();

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = new fabric.Canvas(canvasRef.current);
      const { removeAllListeners } = addEventListeners(canvas);

      fabricCanvasRef.current = canvas;
      addCanvas(sku, slideIndex, canvas);

      // Clean up on unmount
      return () => {
        canvas.dispose();
        removeAllListeners();
      };
    }
  }, [sku, slideIndex, addCanvas]);

  useEffect(() => {
    const renderCanvas = async () => {
      const canvas = fabricCanvasRef.current!;
      canvas.clear();

      ImageService.addImages(images);

      const containerWidth = frame.size.x;
      const originalHeight = frame.size.y;
      const scaleFactor = 600 / originalHeight;

      const adjustedWidth = containerWidth * scaleFactor;
      const adjustedHeight = originalHeight * scaleFactor;

      if (containerRef.current) {
        containerRef.current.style.width = `${adjustedWidth}px`;
        containerRef.current.style.height = `${adjustedHeight}px`;
      }

      canvas.setWidth(adjustedWidth);
      canvas.setHeight(adjustedHeight);

      if (fabricjsData) {
        canvas.loadFromJSON(fabricjsData, () => loadSubresources(canvas, fabricjsData));
      } else {
        frame.relativeTransform = [[scaleFactor, 0, 0], [0, scaleFactor, 0]];
        await drawFrame(canvas, frame, [], true);
      }

      canvas.renderAll();
    };

    renderCanvas();
  }, [frame, images, fabricjsData]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', marginRight: '10px', marginBottom: '40px' }}>
      <canvas id="canvas" ref={canvasRef}></canvas>
    </div>
  );
};

export default CanvasComponent;
