// src/utils/copy-paste-delete-listeners.ts
import { useEditingMenuStore } from '../stores/editing-menu-storage';
import { useCanvasStore } from '../stores/canvas-store';
import { useClipboardStore } from '../stores/clipboard-store';
import { fabric } from 'fabric';

function isTextEditingInProgress() {
    return document.activeElement && (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA' || (document.activeElement as any).isContentEditable);
}

async function handlePaste(event: ClipboardEvent) {
    const clipboardStore = useClipboardStore.getState();
    const canvasStore = useCanvasStore.getState();
    const activeCanvas = canvasStore.activeCanvas;

    if (!activeCanvas) return;

    // Check if a text field is in focus
    if (isTextEditingInProgress()) {
        return; // Let the default behavior happen
    }

    const items = event.clipboardData?.items;
    if (!items) return;

    for (const item of items) {
        if (item.type.startsWith('image/')) {
            const blob = item.getAsFile();
            if (blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const img = new Image();
                    img.src = e.target?.result as string;
                    img.onload = function () {
                        const image = new fabric.Image(img);
                        activeCanvas.add(image);
                        activeCanvas.setActiveObject(image);
                        activeCanvas.renderAll();
                        canvasStore.setUnsavedChanges(true);
                    };
                };
                reader.readAsDataURL(blob);
                return; // Prevent further pasting if an image is found
            }
        }
    }

    const copiedObjects = await clipboardStore.pasteFromClipboard();
    console.log('paste', copiedObjects);
    if (copiedObjects) {
        copiedObjects.forEach(obj => {
            activeCanvas.add(obj);
        });
        const selection = new fabric.ActiveSelection(copiedObjects, {
            canvas: activeCanvas,
        });
        activeCanvas.setActiveObject(selection);
        activeCanvas.renderAll();
        canvasStore.setUnsavedChanges(true);
    }
}

async function handleCopy(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        // Check if a text field is in focus
        if (isTextEditingInProgress()) {
            return; // Let the default behavior happen
        }

        const selectedObjects = useEditingMenuStore.getState().selectedObjects;
        const clipboardStore = useClipboardStore.getState();

        if (selectedObjects.length > 0) {
            console.log('selectedObjects', selectedObjects);
            clipboardStore.copyToClipboard(selectedObjects);

            // Determine the type of object and set a string to the system clipboard
            let clipboardText = '';
            if (selectedObjects.length === 1) {
                const obj = selectedObjects[0];
                if (obj.type === 'image') {
                    clipboardText = 'Fabric image';
                } else if (obj.type === 'text') {
                    clipboardText = 'Fabric text';
                } else if (obj.type === 'group') {
                    clipboardText = 'Fabric group';
                } else {
                    clipboardText = 'Fabric object';
                }
            } else {
                clipboardText = 'Fabric objects';
            }

            try {
                await navigator.clipboard.writeText(clipboardText);
                console.log('Clipboard text set to:', clipboardText);
            } catch (err) {
                console.error('Failed to write to clipboard:', err);
            }
        }
    }
}

function handleDelete(event: KeyboardEvent) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
        // Check if a text field is in focus
        if (isTextEditingInProgress()) {
            return; // Let the default behavior happen
        }

        const canvasStore = useCanvasStore.getState();
        const activeCanvas = canvasStore.activeCanvas;
        if (activeCanvas) {
            const selectedObjects = activeCanvas.getActiveObjects();
            if (selectedObjects.length > 0) {
                selectedObjects.forEach(obj => activeCanvas.remove(obj));
                activeCanvas.discardActiveObject();
                activeCanvas.renderAll();
                canvasStore.setUnsavedChanges(true);
            }
        }
    }
}

export function setupCopyPasteDeleteListeners() {
    document.addEventListener('keydown', handleCopy);
    document.addEventListener('paste', handlePaste);
    document.addEventListener('keydown', handleDelete);

    return () => {
        document.removeEventListener('keydown', handleCopy);
        document.removeEventListener('paste', handlePaste);
        document.removeEventListener('keydown', handleDelete);
    };
}
