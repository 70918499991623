import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import { ButtonGroup, Button, Form, Row, Col } from 'react-bootstrap';
import { log } from 'console';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faItalic,
    faUnderline,
    faStrikethrough,
    faFont,
    faBold,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faAlignJustify
} from '@fortawesome/free-solid-svg-icons';

interface TextEditProps {
    objects: fabric.Textbox[];
}

const TextEdit: React.FC<TextEditProps> = ({ objects }) => {
    const firstObject = objects[0];

    const getEffectiveSelectionRange = (obj: fabric.Textbox) => {
        if (obj.selectionStart === 0 && obj.selectionEnd === 0) {
            return { selectionStart: 0, selectionEnd: obj.text?.length || 0 };
        }
        return { selectionStart: obj.selectionStart, selectionEnd: obj.selectionEnd };
    };

    const { selectionStart, selectionEnd } = getEffectiveSelectionRange(firstObject);
    const selectionStyles = firstObject.getSelectionStyles(selectionStart, selectionEnd);

    const [fontSize, setFontSize] = useState(selectionStyles[0]?.fontSize || firstObject.fontSize || 16);
    const [charSpacing, setCharSpacing] = useState(selectionStyles[0]?.charSpacing || firstObject.charSpacing || 0);
    const [lineHeight, setLineHeight] = useState(selectionStyles[0]?.lineHeight || firstObject.lineHeight || 1.2);
    const [fontStyle, setFontStyle] = useState(selectionStyles[0]?.fontStyle || firstObject.fontStyle || 'normal');
    const [underline, setUnderline] = useState(selectionStyles[0]?.underline || firstObject.underline || false);
    const [overline, setOverline] = useState(selectionStyles[0]?.overline || firstObject.overline || false);
    const [linethrough, setLinethrough] = useState(selectionStyles[0]?.linethrough || firstObject.linethrough || false);
    const [textAlign, setTextAlign] = useState(firstObject.textAlign || 'left');
    const [fontFamily, setFontFamily] = useState(selectionStyles[0]?.fontFamily || firstObject.fontFamily || 'Arial');
    const [fontWeight, setFontWeight] = useState(selectionStyles[0]?.fontWeight || firstObject.fontWeight || 'normal');

    useEffect(() => {
        if (objects.length > 0) {
            const obj = objects[0];
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            const selectionStyles = obj.getSelectionStyles(selectionStart, selectionEnd);

            setFontSize(selectionStyles[0]?.fontSize || obj.fontSize || 16);
            setCharSpacing(selectionStyles[0]?.charSpacing || obj.charSpacing || 0);
            setLineHeight(selectionStyles[0]?.lineHeight || obj.lineHeight || 1.2);
            setFontStyle(selectionStyles[0]?.fontStyle || obj.fontStyle || 'normal');
            setUnderline(selectionStyles[0]?.underline || obj.underline || false);
            setOverline(selectionStyles[0]?.overline || obj.overline || false);
            setLinethrough(selectionStyles[0]?.linethrough || obj.linethrough || false);
            setTextAlign(obj.textAlign || 'left');
            setFontFamily(selectionStyles[0]?.fontFamily || obj.fontFamily || 'Arial');
            setFontWeight(selectionStyles[0]?.fontWeight || obj.fontWeight || 'normal');
        }
    }, [objects]);

    const handleUpdate = (callback: (obj: fabric.Textbox) => void) => {
        objects.forEach(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            log(selectionStart, selectionEnd);

            callback(obj);
            obj.canvas?.renderAll(); // Re-render the canvas
        });
    };

    const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSize = parseFloat(e.target.value);
        setFontSize(newSize);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.setSelectionStyles({ fontSize: newSize }, selectionStart, selectionEnd);
        });
    };

    const handleCharSpacingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSpacing = parseFloat(e.target.value);
        setCharSpacing(newSpacing);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.set({ charSpacing: newSpacing });
        });
    };

    const handleLineHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newLineHeight = parseFloat(e.target.value);
        setLineHeight(newLineHeight);
        handleUpdate(obj => {
            obj.set({ lineHeight: newLineHeight });
        });
    };

    const handleFontStyleChange = (style: 'normal' | 'italic') => {
        setFontStyle(style);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.setSelectionStyles({ fontStyle: style }, selectionStart, selectionEnd);
        });
    };

    const handleUnderlineChange = () => {
        const newValue = !underline;
        setUnderline(newValue);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.setSelectionStyles({ underline: newValue }, selectionStart, selectionEnd);
        });
    };

    const handleOverlineChange = () => {
        const newValue = !overline;
        setOverline(newValue);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.setSelectionStyles({ overline: newValue }, selectionStart, selectionEnd);
        });
    };

    const handleLinethroughChange = () => {
        const newValue = !linethrough;
        setLinethrough(newValue);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.setSelectionStyles({ linethrough: newValue }, selectionStart, selectionEnd);
        });
    };

    const handleTextAlignChange = (align: 'left' | 'center' | 'right' | 'justify') => {
        setTextAlign(align);
        handleUpdate(obj => {
            obj.set({ textAlign: align });
        });
    };

    const handleBoldChange = () => {
        const newWeight = fontWeight === 'bold' ? 'normal' : 'bold';
        setFontWeight(newWeight);
        handleUpdate(obj => {
            const { selectionStart, selectionEnd } = getEffectiveSelectionRange(obj);
            obj.setSelectionStyles({ fontWeight: newWeight }, selectionStart, selectionEnd);
        });
    };

    return (
        <div>
            <h5>Text Edit</h5>
            <Form>
                <Form.Group as={Row} controlId="fontSizeControl" className="mb-3">
                    <Form.Label column sm={7}>Font Size</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            type="number"
                            value={Number(fontSize).toFixed(1)}
                            onChange={handleFontSizeChange}
                            step="0.1"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="charSpacingControl" className="mb-3">
                    <Form.Label column sm={7}>Character Spacing</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            type="number"
                            value={Number(charSpacing).toFixed(1)}
                            onChange={handleCharSpacingChange}
                            step="0.1"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="lineHeightControl" className="mb-3">
                    <Form.Label column sm={7}>Line Height</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            type="number"
                            step="0.1"
                            value={Number(lineHeight).toFixed(1)}
                            onChange={handleLineHeightChange}
                        />
                    </Col>
                </Form.Group>
                <Form.Group controlId="fontStyleControl" className="mb-3">
                    <Form.Label>Font Style</Form.Label>
                    <ButtonGroup>
                        <Button variant={fontStyle === 'normal' && !fontWeight.includes('bold') ? 'primary' : 'light'} onClick={() => handleFontStyleChange('normal')}>
                            <FontAwesomeIcon icon={faFont} />
                        </Button>
                        <Button variant={fontStyle === 'italic' ? 'primary' : 'light'} onClick={() => handleFontStyleChange('italic')}>
                            <FontAwesomeIcon icon={faItalic} />
                        </Button>
                        <Button variant={fontWeight === 'bold' ? 'primary' : 'light'} onClick={handleBoldChange}>
                            <FontAwesomeIcon icon={faBold} />
                        </Button>
                    </ButtonGroup>
                </Form.Group>
                <Form.Group controlId="textDecorationControl" className="mb-3">
                    <Form.Label>Text Decoration</Form.Label>
                    <ButtonGroup>
                        <Button variant={underline ? 'primary' : 'light'} onClick={handleUnderlineChange}>
                            <FontAwesomeIcon icon={faUnderline} />
                        </Button>
                        <Button variant={linethrough ? 'primary' : 'light'} onClick={handleLinethroughChange}>
                            <FontAwesomeIcon icon={faStrikethrough} />
                        </Button>
                    </ButtonGroup>
                </Form.Group>
                <Form.Group controlId="textAlignControl" className="mb-3">
                    <Form.Label>Text Align</Form.Label>
                    <ButtonGroup>
                        <Button variant={textAlign === 'left' ? 'primary' : 'light'} onClick={() => handleTextAlignChange('left')}>
                            <FontAwesomeIcon icon={faAlignLeft} />
                        </Button>
                        <Button variant={textAlign === 'center' ? 'primary' : 'light'} onClick={() => handleTextAlignChange('center')}>
                            <FontAwesomeIcon icon={faAlignCenter} />
                        </Button>
                        <Button variant={textAlign === 'right' ? 'primary' : 'light'} onClick={() => handleTextAlignChange('right')}>
                            <FontAwesomeIcon icon={faAlignRight} />
                        </Button>
                        <Button variant={textAlign === 'justify' ? 'primary' : 'light'} onClick={() => handleTextAlignChange('justify')}>
                            <FontAwesomeIcon icon={faAlignJustify} />
                        </Button>
                    </ButtonGroup>
                </Form.Group>
                <Form.Group controlId="fontFamilyControl" className="mb-3">
                    <Form.Label>Font Family</Form.Label>
                    <Form.Control type="text" value={fontFamily} readOnly />
                </Form.Group>
            </Form>
        </div>
    );
};

export default TextEdit;
