import FontFaceObserver from 'fontfaceobserver';

interface FontManifest {
    [fontPostScriptName: string]: string;
}

class FontService {
    private static fontManifest: FontManifest | null = null;

    private static async loadFontManifest(): Promise<FontManifest> {
        if (!FontService.fontManifest) {
            const manifestPath = '/fonts/font-manifest.json';
            try {
                const response = await fetch(manifestPath);
                if (!response.ok) {
                    throw new Error(`Failed to load font manifest from ${manifestPath}`);
                }
                FontService.fontManifest = await response.json();
            } catch (error) {
                console.error(`Failed to load font manifest from ${manifestPath}:`, error);  // Log the actual error
                throw new Error(`Failed to load font manifest from ${manifestPath}: ${error instanceof Error ? error.message : 'Unknown error'}`);
            }
        }
        if (FontService.fontManifest === null) {
            throw new Error('Font manifest is null');
        }
        return FontService.fontManifest;
    }

    private static addFontFaceRule(fontName: string, fontUrl: string): void {
        const style = document.createElement('style');
        style.innerHTML = `
            @font-face {
                font-family: '${fontName}';
                src: url('${fontUrl}');
            }
        `;
        document.head.appendChild(style);
    }

    private static async loadFontWithFallback(fontPostScriptName: string, fontFamily: string): Promise<string> {
        const manifest = await FontService.loadFontManifest();
        const fontNamesToTry = [
            fontPostScriptName,
            fontPostScriptName.replace('Roman', ''),
            `${fontFamily.replace(' ', '')}-Regular`
        ];

        const fontsNotFoundInManifest: string[] = [];
        const fontsFailedToLoad: string[] = [];

        for (const fontName of fontNamesToTry) {
            const fontUrl = manifest[fontName];
            if (fontUrl) {
                try {
                    FontService.addFontFaceRule(fontName, fontUrl); // Add @font-face rule dynamically
                    const font = new FontFaceObserver(fontName);
                    await font.load();
                    // console.log(`${fontName} has loaded.`);
                    return fontName; // Font loaded successfully, return the resolved font name
                } catch (error) {
                    console.error(`Failed to load font: ${fontName}`, error);  // Log the actual error
                    fontsFailedToLoad.push(fontName);
                }
            } else {
                fontsNotFoundInManifest.push(fontName);
            }
        }

        const errorMessage = `All attempts to find font failed. ` +
            `Fonts not found in manifest: ${fontsNotFoundInManifest.join(', ')}. ` +
            `Fonts found in manifest but failed to load: ${fontsFailedToLoad.join(', ')}.`;
        throw new Error(errorMessage);
    }

    static async getFont(fontFamily: string, fontPostScriptName?: string): Promise<string> {
        const knownPostScriptNames = [
            "Thin", "ExtraLight", "Light", "Regular", "Medium", "SemiBold", "Bold", "ExtraBold", "Black",
            "Thin Italic", "ExtraLight Italic", "Light Italic", "Italic", "Medium Italic", "SemiBold Italic",
            "Bold Italic", "ExtraBold Italic", "Black Italic"
        ];

        function checkIfHasPostScriptName(fontName) {
            return knownPostScriptNames.some(weight => fontName.includes(weight));
        }

        if (checkIfHasPostScriptName(fontFamily)) {
            fontPostScriptName = fontFamily;
        } else if (!fontPostScriptName) {
            fontPostScriptName = `${fontFamily.replace(' ', '')}-Regular`;
        }

        try {
            const resolvedFontName = await FontService.loadFontWithFallback(fontPostScriptName, fontFamily);
            return resolvedFontName;
        } catch (error) {
            console.warn(error instanceof Error ? error.message : 'Unknown error occurred during font loading');
            return 'Arial'; // Default font family
        }
    }
}

export default FontService;
