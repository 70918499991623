// src/utils/downloadUtils.ts

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { fabric } from 'fabric';

const addSlidesToZip = async (zip: JSZip, sku: string, slides: fabric.Canvas[]) => {
    const folder = zip.folder(sku);

    for (let i = 0; i < slides.length; i++) {
        const canvas = slides[i];
        if (canvas) {
            // Save the original state
            const originalWidth = canvas.getWidth();
            const originalHeight = canvas.getHeight();
            const originalZoom = canvas.getZoom();

            // Scale the canvas to 2x resolution
            canvas.setWidth(originalWidth * 2);
            canvas.setHeight(originalHeight * 2);
            canvas.setZoom(originalZoom * 2);

            // Wait for the canvas to render
            await new Promise((resolve) => {
                canvas.renderAll();
                resolve(null);
            });

            const dataURL = canvas.toDataURL({ format: 'jpeg', quality: 0.8 });
            const response = await fetch(dataURL);
            const blob = await response.blob();
            folder.file(`${i + 1}.jpeg`, blob);

            // Revert the canvas to its original state
            canvas.setWidth(originalWidth);
            canvas.setHeight(originalHeight);
            canvas.setZoom(originalZoom);

            // Render the canvas back to its original state
            canvas.renderAll();
        }
    }
};

export const downloadInfographicsForProduct = async (sku: string, canvases: Map<string, fabric.Canvas[]>) => {
    const zip = new JSZip();
    const slides = canvases.get(sku);

    if (slides) {
        await addSlidesToZip(zip, sku, slides);
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${sku}_infographics.zip`);
    });
};

export const downloadAllInfographics = async (canvases: Map<string, fabric.Canvas[]>) => {
    const zip = new JSZip();
    for (const [sku, slides] of canvases.entries()) {
        await addSlidesToZip(zip, sku, slides);
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'infographics.zip');
    });
};
