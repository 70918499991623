import { log } from 'console';
import useImageStore from '../stores/images-store';
import { retry } from 'ts-retry-promise';

class ImageService {
    private static images: Map<string, HTMLImageElement | Promise<HTMLImageElement>> = new Map();

    static async addImages(imageRefs: string[] | { [key: string]: any }): Promise<void> {
        const store = useImageStore.getState();
        const refs = Array.isArray(imageRefs) ? imageRefs : Object.keys(imageRefs);

        for (const imageRef of refs) {
            if (!this.images.has(imageRef) && !store.getImage(imageRef)) {
                // log('loading image since not found in cache or store', imageRef)
                this.images.set(imageRef, this.loadImageWithRetry(imageRef));
            }
        }
    }

    static async getImage(imageRef: string): Promise<HTMLImageElement> {
        // log('looking for image', imageRef)
        if (this.images.has(imageRef)) {
            // log('found cached image', imageRef)
            const image = this.images.get(imageRef);
            if (image instanceof Promise) {
                return image;
            }
            return image as HTMLImageElement;
        }

        const store = useImageStore.getState();
        const cachedBlob = store.getImage(imageRef);

        if (cachedBlob) {
            // log('found stored image', imageRef)
            const imagePromise = this.blobToImageElement(cachedBlob).then(imageElement => {
                this.images.set(imageRef, imageElement);
                return imageElement;
            });
            this.images.set(imageRef, imagePromise);
            return imagePromise;
        }

        // Fallback option: will load image if not found anywhere and save it to store
        return ImageService.loadImage(imageRef);
        // throw new Error(`Image with reference ${JSON.stringify(imageRef)} not found`);
    }

    private static async loadImageWithRetry(imageRef: string): Promise<HTMLImageElement> {
        return retry(() => this.loadImage(imageRef), { retries: 3, delay: 1000 });
    }

    private static async loadImage(imageRef: string): Promise<HTMLImageElement> {
        const url = `/api/images/${imageRef}`;
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = async () => {
                const blob = await this.fetchImageAsBlobWithRetry(url);
                useImageStore.getState().addImage(imageRef, blob);
                this.images.set(imageRef, img);
                resolve(img);
            };
            img.onerror = () => {
                this.images.delete(imageRef);
                reject(new Error(`Failed to load image from URL ${url}`));
            };
        });
    }

    private static async fetchImageAsBlobWithRetry(url: string): Promise<Blob> {
        return retry(() => this.fetchImageAsBlob(url), { retries: 3, delay: 1000 });
    }

    private static async fetchImageAsBlob(url: string): Promise<Blob> {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch image from URL ${url}`);
        }
        return await response.blob();
    }

    private static blobToImageElement(blob: Blob): Promise<HTMLImageElement> {
        return new Promise((resolve) => {
            const img = new Image();
            const url = URL.createObjectURL(blob);
            img.onload = () => {
                URL.revokeObjectURL(url);
                resolve(img);
            };
            img.src = url;
        });
    }
}

export default ImageService;
