import { fabric } from 'fabric';
import ImageService from '../services/ImageService';
import { uploadImageToServer } from './utils';

export function recolorIconFromSource(sourceImage: HTMLImageElement, targetImage: HTMLImageElement): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        // Create a canvas to extract the color from the source image
        const sourceCanvas = document.createElement('canvas');
        const sourceCtx = sourceCanvas.getContext('2d');
        if (!sourceCtx) {
            return reject(new Error('Failed to get 2D context for source image'));
        }

        // Set canvas dimensions to match the source image
        sourceCanvas.width = sourceImage.width;
        sourceCanvas.height = sourceImage.height;

        // Draw the source image onto the canvas
        sourceCtx.drawImage(sourceImage, 0, 0);

        // Get the image data and extract the dominant color
        const sourceImageData = sourceCtx.getImageData(0, 0, sourceCanvas.width, sourceCanvas.height).data;
        let r = 0, g = 0, b = 0, count = 0;

        for (let i = 0; i < sourceImageData.length; i += 4) {
            const alpha = sourceImageData[i + 3];
            if (alpha > 0) {
                r += sourceImageData[i];
                g += sourceImageData[i + 1];
                b += sourceImageData[i + 2];
                count++;
            }
        }

        r = Math.floor(r / count);
        g = Math.floor(g / count);
        b = Math.floor(b / count);

        const color = `rgb(${r},${g},${b})`;

        // Create a canvas to recolor the target image
        const targetCanvas = document.createElement('canvas');
        const targetCtx = targetCanvas.getContext('2d');
        if (!targetCtx) {
            return reject(new Error('Failed to get 2D context for target image'));
        }

        // Set canvas dimensions to match the target image
        targetCanvas.width = targetImage.width;
        targetCanvas.height = targetImage.height;

        // Draw the target image onto the canvas
        targetCtx.drawImage(targetImage, 0, 0);

        // Get the image data and recolor it
        const targetImageData = targetCtx.getImageData(0, 0, targetCanvas.width, targetCanvas.height);
        const targetData = targetImageData.data;

        for (let i = 0; i < targetData.length; i += 4) {
            if (targetData[i + 3] > 0) { // if alpha > 0
                targetData[i] = r;
                targetData[i + 1] = g;
                targetData[i + 2] = b;
            }
        }

        // Put the recolored image data back onto the canvas
        targetCtx.putImageData(targetImageData, 0, 0);

        // Create a new image element with the recolored data
        const recoloredImage = new Image();
        recoloredImage.onload = () => resolve(recoloredImage);
        recoloredImage.onerror = reject;
        recoloredImage.src = targetCanvas.toDataURL('image/png');
    });
}


export async function handleIconClick(object: fabric.Object, thumbnailUrl: string, searchText: string): Promise<void> {
    const group = object as fabric.Group;
    const firstChild = group.getObjects()[0];

    // Check if the existing fill is a pattern
    if (firstChild.fill && (firstChild.fill as fabric.Pattern).source) {
        const existingPattern = (firstChild.fill as fabric.Pattern);
        const sourceIconRef = group.metadata.fills[0].imageRef;

        const sourceIcon: HTMLImageElement = await ImageService.getImage(sourceIconRef);

        // Create HTMLImageElement from thumbnailUrl
        const thumbnailImage = new Image();
        thumbnailImage.src = thumbnailUrl;

        // Ensure the image is loaded before proceeding
        await new Promise<void>((resolve, reject) => {
            thumbnailImage.onload = () => resolve();
            thumbnailImage.onerror = reject;
        });

        try {
            // Recolor the new icon with the color from the existing pattern
            const recoloredImage = await recolorIconFromSource(sourceIcon, thumbnailImage);

            const imageRef = await uploadImageToServer(recoloredImage);

            // Get the dimensions of the object
            const objectWidth = firstChild.getScaledWidth();
            const objectHeight = firstChild.getScaledHeight();

            // Calculate the scaling factors
            const scaleX = objectWidth / recoloredImage.width;
            const scaleY = objectHeight / recoloredImage.height;

            // Create a pattern from the recolored image
            const pattern = new fabric.Pattern({
                source: recoloredImage,
                repeat: 'no-repeat',
                patternTransform: [scaleX, 0, 0, scaleY, 0, 0],
                imageRef: imageRef
            });

            // Set the pattern as the fill for the first child of the group
            firstChild.set('fill', pattern);
            group.canvas?.renderAll();
            object.metadata.fills[0].icon_description = searchText;
        } catch (error) {
            console.error('Error adding icon:', error);
        }
    } else {
        console.error('Existing fill is not a pattern or has no source');
    }
}
