import { z } from 'zod';

// Define a schema for Color
export const colorSchema = z.object({
    r: z.number(),
    g: z.number(),
    b: z.number(),
    a: z.number(),
});

// Define a schema for Constraint
export const constraintSchema = z.object({
    vertical: z.string(),
    horizontal: z.string(),
});

// Define a schema for Size
export const sizeSchema = z.object({
    x: z.number(),
    y: z.number(),
});

// Define a schema for Box
export const boxSchema = z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
});

// Define a schema for Box
export const rotatedBoxSchema = z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    rotation: z.number(),
});

// Define a schema for Transform
export const transformSchema = z.array(z.array(z.number()).length(3)).length(2);

// Define a schema for ColorStop
export const colorStopSchema = z.object({
    position: z.number(),
    color: colorSchema,
});

// Define a schema for ImageFilters
export const imageFiltersSchema = z.object({
    exposure: z.number().optional().nullable(),
    contrast: z.number().optional().nullable(),
    saturation: z.number().optional().nullable(),
    temperature: z.number().optional().nullable(),
    tint: z.number().optional().nullable(),
    highlights: z.number().optional().nullable(),
    shadows: z.number().optional().nullable(),
});

// Define a schema for VariableAlias
export const variableAliasSchema = z.object({
    // Define properties based on your needs
});

// Define a schema for Paint
export const paintSchema = z.object({
    type: z.string(),
    visible: z.boolean().default(true),
    opacity: z.number().default(1.0),
    color: colorSchema.optional().nullable(),
    blendMode: z.string().optional().nullable(),
    gradientHandlePositions: z.array(sizeSchema).optional().nullable(),
    gradientStops: z.array(colorStopSchema).optional().nullable(),
    scaleMode: z.string().optional().nullable(),
    imageTransform: transformSchema.optional().nullable(),
    scalingFactor: z.number().optional().nullable(),
    rotation: z.number().default(0),
    imageRef: z.string().optional().nullable(),
    filters: imageFiltersSchema.optional().nullable(),
    gifRef: z.string().optional().nullable(),
    boundVariables: z.record(variableAliasSchema).optional().nullable(),
    icon_description: z.string().optional().default('')
});

// Define a schema for Effect
export const effectSchema = z.object({
    type: z.string(),
    visible: z.boolean(),
    blendMode: z.string().optional().nullable(),
    offset: sizeSchema.optional().nullable(), // Danger: changed
    radius: z.number().optional().nullable(),
    showShadowBehindNode: z.boolean().optional().nullable(),
    color: colorSchema.optional().nullable(),
    spread: z.number().default(0),
});

// Define a schema for Geometry
export const geometrySchema = z.object({
    path: z.string(),
    windingRule: z.string(),
});

// Define a schema for TypeStyle
export const typeStyleSchema = z.object({
    fontFamily: z.string(),
    fontWeight: z.number(),
    fontSize: z.number(),
    textAlignHorizontal: z.string(),
    textAlignVertical: z.string(),
    letterSpacing: z.number(),
    lineHeightPx: z.number(),
    lineHeightUnit: z.string(),
    italic: z.boolean().default(false),
    lineHeightPercentFontSize: z.number().default(1),
    fills: z.array(paintSchema).default([]),
    hyperlink: z.object({
        type: z.string().optional().nullable(),
        url: z.string().optional().nullable(),
        nodeID: z.string().optional().nullable(),
    }).optional().nullable(),
    fontPostScriptName: z.string().optional().nullable(),
    paragraphSpacing: z.number().default(0),
    paragraphIndent: z.number().default(0),
    listSpacing: z.number().default(0),
    textCase: z.string().default("ORIGINAL"),
    textDecoration: z.string().default("NONE"),
    textAutoResize: z.string().default("NONE"),
    textTruncation: z.string().default("DISABLED"),
    maxLines: z.number().optional().nullable(),
    opentypeFlags: z.record(z.number()).default({}),
    lineHeightPercent: z.number().default(100),
    leadingTrim: z.string().optional().nullable(),
});

// Define a schema for Vector
export const vectorSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal('VECTOR'),
    scrollBehavior: z.string(),
    locked: z.boolean().default(false),
    exportSettings: z.any().optional().nullable(),
    blendMode: z.string().optional().nullable(),
    preserveRatio: z.boolean().default(false),
    constraints: constraintSchema.optional().nullable(),
    transitionNodeID: z.string().optional().nullable(),
    transitionDuration: z.number().optional().nullable(),
    transitionEasing: z.string().optional().nullable(),
    opacity: z.number().default(1.0),
    absoluteBoundingBox: boxSchema.optional().nullable(),
    absoluteRenderBounds: boxSchema.optional().nullable(),
    effects: z.array(effectSchema).optional().nullable(),
    size: sizeSchema.optional().nullable(),
    relativeTransform: transformSchema.optional().nullable(),
    isMask: z.boolean().default(false),
    fills: z.array(paintSchema).optional().nullable(),
    fillGeometry: z.array(geometrySchema).optional().nullable(),
    strokes: z.array(paintSchema).optional().nullable(),
    strokeWeight: z.number().optional().nullable(),
    individualStrokeWeights: z.string().optional().nullable(),
    strokeCap: z.string().optional().nullable(),
    strokeJoin: z.string().optional().nullable(),
    strokeDashes: z.array(z.number()).optional().nullable(),
    strokeMiterAngle: z.number().default(28.96),
    strokeGeometry: z.array(geometrySchema).optional().nullable(),
    strokeAlign: z.string().optional().nullable(),
    styles: z.record(z.string()).optional().nullable(),
    bbox: boxSchema.optional().nullable(),
    rotation: z.number().optional().nullable(),
    fillOverrideTable: z.any().optional().nullable(),
    visible: z.boolean().default(true),
    rectangleCornerRadii: z.any().default(1),
    cornerSmoothing: z.number().default(0),
    layoutSizingHorizontal: z.string().optional().nullable(),
    layoutSizingVertical: z.string().optional().nullable(),
    layoutWrapString: z.string().optional().nullable(),
    layoutAlign: z.string().optional().nullable(),
    layoutGrow: z.number().optional().nullable(),
    optimized_scale: z.number().default(1),
    optimized_bbox: z.any().optional().nullable(),
});

// Define a schema for Ellipse
export const ellipseSchema = vectorSchema.extend({
    type: z.literal('ELLIPSE'),
    arcData: z.any().optional().nullable(),
});

// Define a schema for RegularPolygon
export const regularPolygonSchema = vectorSchema.extend({
    type: z.literal('REGULAR_POLYGON'),
    cornerRadius: z.number().optional().nullable(),
    cornerSmoothing: z.number().optional().nullable(),
});

// Define a schema for Star
export const starSchema = vectorSchema.extend({
    type: z.literal('STAR'),
});

// Define a schema for Line
export const lineSchema = vectorSchema.extend({
    type: z.literal('LINE'),
});


// Define a schema for Rectangle
export const rectangleSchema = vectorSchema.extend({
    type: z.literal('RECTANGLE'),
    cornerRadius: z.number().optional().nullable(),
    rectangleCornerRadii: z.array(z.number()).optional().nullable(),
    cornerSmoothing: z.number().optional().nullable(),
});

// Define a schema for Text
export const textSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal('TEXT'),
    scrollBehavior: z.string(),
    blendMode: z.string(),
    absoluteBoundingBox: boxSchema,
    absoluteRenderBounds: boxSchema.optional().nullable(),
    constraints: constraintSchema,
    relativeTransform: transformSchema,
    size: sizeSchema, // DANGER: changed
    fills: z.array(paintSchema),
    strokes: z.array(paintSchema),
    strokeWeight: z.number(),
    strokeAlign: z.string(),
    strokeGeometry: z.array(geometrySchema),
    effects: z.array(effectSchema),
    characters: z.union([z.string(), z.array(z.string())]),
    style: typeStyleSchema,
    layoutVersion: z.number().optional().nullable(),
    characterStyleOverrides: z.array(z.any()).optional().nullable(),
    styleOverrideTable: z.record(z.any()).optional().nullable(),
    lineTypes: z.array(z.string()).optional().nullable(),
    lineIndentations: z.array(z.number()).optional().nullable(),
    bbox: boxSchema.optional().nullable(),
    rotation: z.number().optional().nullable(),
    exportSettings: z.any().optional().nullable(),
    visible: z.boolean().default(true),
    layoutAlign: z.string().optional().nullable(),
    layoutPositioning: z.string().optional().nullable(),
    layoutGrow: z.number().optional().nullable(),
    layoutSizingHorizontal: z.string().optional().nullable(),
    layoutSizingVertical: z.string().optional().nullable(),
    layoutWrapString: z.string().optional().nullable(),
    fillGeometry: z.array(geometrySchema).optional().nullable(),
    strokeJoin: z.string().optional().nullable(),
});

const mixedSchema = z.lazy(() => z.union([
    rectangleSchema,
    vectorSchema,
    textSchema,
    ellipseSchema,
    regularPolygonSchema,
    lineSchema,
    starSchema,
    frameSchema, // This will reference the final frameSchema
    groupSchema, // This will reference the final groupSchema
    instanceSchema,
    componentSchema,
    componentSetSchema,
    booleanOperationSchema,
]));

// Define the frameSchema with explicit type annotation to handle circular reference
export const frameSchema: z.ZodObject<any, any, any> = z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal('FRAME'),
    scrollBehavior: z.string(),
    blendMode: z.string(),
    absoluteBoundingBox: boxSchema,
    absoluteRenderBounds: boxSchema.optional().nullable(),
    constraints: constraintSchema,
    relativeTransform: transformSchema,
    size: sizeSchema,
    clipsContent: z.boolean().optional().nullable(),
    background: z.array(z.union([paintSchema, z.null()])).optional().nullable(),
    fills: z.array(paintSchema),
    strokes: z.array(paintSchema),
    strokeWeight: z.number(),
    strokeAlign: z.string(),
    backgroundColor: colorSchema.optional().nullable(),
    fillGeometry: z.array(geometrySchema),
    strokeGeometry: z.array(geometrySchema),
    effects: z.array(effectSchema),
    children: z.array(mixedSchema), // Use z.lazy for circular reference
    exportSettings: z.any().optional().nullable(),
    bbox: boxSchema.optional().nullable(),
    cornerRadius: z.number().optional().nullable(),
    cornerSmoothing: z.number().optional().nullable(),
    layoutGrids: z.record(z.string()).default({}),
    styles: z.record(z.any()).default({}),
    rotation: z.number().optional().nullable(),
    rectangleCornerRadii: z.array(z.number()).optional().nullable(),
    layoutSizingHorizontal: z.string().optional().nullable(),
    layoutSizingVertical: z.string().optional().nullable(),
    layoutWrapString: z.string().optional().nullable(),
    layoutWrap: z.string().optional().nullable(),
    layoutMode: z.string().optional().nullable(),
    itemSpacing: z.number().optional().nullable(),
    layoutAlign: z.string().optional().nullable(),
    layoutGrow: z.number().optional().nullable(),
    counterAxisAlignItems: z.string().optional().nullable(),
    counterAxisSizingMode: z.string().default('AUTO'),
    primaryAxisSizingMode: z.string().default('AUTO'),
    primaryAxisAlignItems: z.string().default('MIN'),
    strokeDashes: z.array(z.number()).optional().nullable(),
    counterAxisSpacing: z.number().optional().nullable(),
    paddingLeft: z.number().default(0),
    paddingRight: z.number().default(0),
    paddingTop: z.number().default(0),
    paddingBottom: z.number().default(0),
    hotReplaceRelativeTransform: transformSchema.optional().nullable(),
    visible: z.boolean().default(true),
    opacity: z.number().default(1.0),
    locked: z.boolean().default(true),
    preserveRatio: z.boolean().default(true),
}).strict();

// Define the groupSchema extending frameSchema
const groupSchema: z.ZodObject<any, any, any> = frameSchema.extend({
    type: z.literal('GROUP'),
});

// Define the instanceSchema extending frameSchema
const instanceSchema: z.ZodObject<any, any, any> = frameSchema.extend({
    type: z.literal('INSTANCE'),
});

// Define the componentSchema extending frameSchema
const componentSchema: z.ZodObject<any, any, any> = frameSchema.extend({
    type: z.literal('COMPONENT'),
});

// Define the componentSetSchema extending frameSchema
const componentSetSchema: z.ZodObject<any, any, any> = frameSchema.extend({
    type: z.literal('COMPONENT_SET'),
});

// Define the booleanOperationSchema extending frameSchema
const booleanOperationSchema: z.ZodObject<any, any, any> = frameSchema.extend({
    type: z.literal('BOOLEAN_OPERATION'),
});

// Define a schema for Canvas
export const canvasSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal('CANVAS'),
    scrollBehavior: z.string(),
    backgroundColor: colorSchema,
    prototypeStartNodeID: z.any().optional().nullable(),
    flowStartingPoints: z.array(z.any()),
    prototypeDevice: z.record(z.string()),
    children: z.array(mixedSchema),
});

// Define a schema for Document
export const documentSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal('DOCUMENT'),
    scrollBehavior: z.string(),
    // backgroundColor: colorSchema,
    // prototypeStartNodeID: z.string().optional().nullable(),
    // componentSets: z.record(z.any()),
    // components: z.record(z.any()),
    // schemaVersion: z.number(),
    // styles: z.record(z.any()),
    // lastModified: z.string(),
    // thumbnailUrl: z.string(),
    // version: z.string(),
    // role: z.string(),
    // editorType: z.string(),
    // linkAccess: z.string(),
    children: z.array(canvasSchema),
});


export type Color = z.infer<typeof colorSchema>;
export type Constraint = z.infer<typeof constraintSchema>;
export type Size = z.infer<typeof sizeSchema>;
export type Box = z.infer<typeof boxSchema>;
export type Transform = z.infer<typeof transformSchema>;
export type ColorStop = z.infer<typeof colorStopSchema>;
export type ImageFilters = z.infer<typeof imageFiltersSchema>;
export type VariableAlias = z.infer<typeof variableAliasSchema>;
export type Paint = z.infer<typeof paintSchema>;
export type Effect = z.infer<typeof effectSchema>;
export type Geometry = z.infer<typeof geometrySchema>;
export type TypeStyle = z.infer<typeof typeStyleSchema>;
export type Vector = z.infer<typeof vectorSchema>;
export type Ellipse = z.infer<typeof ellipseSchema>;
export type RegularPolygon = z.infer<typeof regularPolygonSchema>;
export type Star = z.infer<typeof starSchema>;
export type Line = z.infer<typeof lineSchema>;
export type Rectangle = z.infer<typeof rectangleSchema>;
export type Text = z.infer<typeof textSchema>;
export type Frame = z.infer<typeof frameSchema>;
export type Group = z.infer<typeof groupSchema>;
export type Canvas = z.infer<typeof canvasSchema>;
export type Document = z.infer<typeof documentSchema>;
export type Mixed = z.infer<typeof mixedSchema>;
