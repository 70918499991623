// src/stores/editing-menu-storage.ts

import { create } from 'zustand';

interface EditingMenuState {
    selectedObjects: fabric.Object[];
    activeCanvas: fabric.Canvas | null;
    setSelectedObjects: (objs: fabric.Object[], canvas: fabric.Canvas) => void;
    clearSelectedObjects: (canvas: fabric.Canvas) => void;
}

export const useEditingMenuStore = create<EditingMenuState>((set, get) => ({
    selectedObjects: [],
    activeCanvas: null,
    setSelectedObjects: (objs, canvas) => {
        if (get().activeCanvas !== canvas) {
            set({ selectedObjects: objs, activeCanvas: canvas });
        } else {
            set({ selectedObjects: objs });
        }
    },
    clearSelectedObjects: (canvas) => {
        if (get().activeCanvas === canvas) {
            set({ selectedObjects: [], activeCanvas: null });
        }
    },
}));
